/* ==========================================================================
  selection_nutrivi.scss
=========================================================================== */

.product-shop{
  width: 100%;
  max-width: rem-calc(1600);
  .owl-item {
    overflow: hidden;
  }
}

.block-views {
  @include flex-grid-column(12);
  position: relative;
  max-width: rem-calc(1600);
  margin: auto;
  margin-bottom: 1rem;
  padding: rem-calc(80 0 130 0);
  &::before{
    position: absolute;
    right: 50%;
    top: 0;
    z-index: -1;
    content: '';
    width: 100%;
    height: 100%;
    max-width: rem-calc(1600);
    background: transparent url(../img/bg_selection.jpg) no-repeat center bottom;
    transform: translateX(50%);
  }

  @include breakpoint(large) {
    padding-bottom: rem-calc(90);
    height: rem-calc(615);
  }

  h2{
    width: 100%;
    max-width: rem-calc(1200);
    margin: auto;
    margin-bottom: rem-calc(12);
    font-family: "ActaDisplay";
    font-size: rem-calc(50);
    line-height: rem-calc(50);
    color: $color-alt;
    font-weight: 900;
    font-style: italic;
    text-align: center;

    @include breakpoint(large) {
      text-align: left;
    }

    @include breakpoint(large only) {
    font-size: rem-calc(40);
    line-height: rem-calc(40);
    }
  }
}

.view-id-remote_products {
  @include flex-grid-row(12);
  justify-content: space-between;

  @include breakpoint(large) {
    flex-wrap: nowrap;
  }

  .description {
    @include flex-grid-column(12, 0);
    flex: 0 0 auto;
    margin: auto;
    margin-top: 0;
    margin-bottom: rem-calc(44);
    font-size: rem-calc(21);
    line-height: rem-calc(25);
    font-weight: 300;
    text-align: center;

    @include breakpoint(large) {
      @include flex-grid-column(3, 0);
      margin: 0;
      text-align: left;
    }

    p{
      max-width: rem-calc(500);
      line-height: 1;
    }

    .button{
      margin-bottom: rem-calc(33);
      background-color: #FFFFFF;
    }

    .logo-nutrivi{
      display: block;
      width: rem-calc(170);
      height: auto;
      margin: auto;
      text-align: center;

      @include breakpoint(large) {
        margin: 0;
        text-align: left;
      }
    }
  }

  .view-content {
    @include flex-grid-column(12);
    height: rem-calc(472);
    margin: auto;
    margin-top: rem-calc(0);

    @include breakpoint(large) {
      @include flex-grid-column(8, 0);
      margin: 0;
      margin-top: rem-calc(-39);
    }
  }

  .owl-stage-outer{}

  .owl-stage{}

  .owl-item{}

  .views-row{
    position: relative;
    width: rem-calc(166);
    height: rem-calc(410);
    /*margin: rem-calc(0 15 0 15);*/
    margin: auto;
    padding: rem-calc(0 0 40 0);

    @include breakpoint(small only) {
      text-align: center;
    }
  }

  .field-name-field-image-url{
    width: rem-calc(166);
    height: rem-calc(212);
    line-height: rem-calc(212);
    margin: rem-calc(0 0 25 0);
    text-align: center;
    background: #FFFFFF;
    box-shadow: rem-calc(0 10 20 0) $shadow-vsoft;

    img{
      display: inline-block;
      vertical-align: middle;
      height: rem-calc(162);
      width: auto;
      /*max-width: rem-calc(108);*/
      margin: auto;
    }
  }

  .field-name-field-title{
    margin-bottom: rem-calc(15);
    font-size: rem-calc(19);
    line-height: rem-calc(17);
    text-transform: uppercase;
    font-weight: 600;
  }

  .field-name-field-product-info,
  .field-name-field-remote-product-category{
    margin: rem-calc(0 0 7 0);
    font-family: 'ActaDisplay',Georgia,serif;
    font-size: rem-calc(14);
    line-height: rem-calc(16);
    font-weight: 500;
    font-style: italic;
    color: darken($color-content, 11%);
  }

  .field-name-field-remote-product-category{
    display: inline-block;
    margin: rem-calc(0 3 3 0);
    padding: rem-calc(5 7);
    font-size: rem-calc(13);
    line-height: rem-calc(13);
    background: #FFFFFF;

    display: none;
  }

  .field-name-field-display-price{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: rem-calc(12 0 0 0);
    padding: rem-calc(11 0 0 0);
    font-size: rem-calc(27);
    line-height: rem-calc(27);
    text-transform: uppercase;
    font-weight: 700;
    border-top: rem-calc(1) solid darken($color-divider, 10%);
  }

  .views-field-nothing{
    a{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      text-indent: -10000px;
    }
  }

  .owl-nav{
    @include flex-grid-row;
    position: absolute;
    left: 50%;
    bottom: rem-calc(-50);
    transform: translateX(-50%);

    @include breakpoint(large) {
      bottom: rem-calc(-20);
    }

    .owl-prev,
    .owl-next{
      @include glyph;
      position: relative;
      width: rem-calc(52);
      height: rem-calc(52);
      margin: rem-calc(0 8 0 8);
      text-indent: -10000px;
      background: #FFFFFF;
      border-radius: 50%;
      box-shadow: rem-calc(0 0 30 0) $shadow-soft;
      transition: background-color 0.25s $easeOutQuad;

      &::before{
        @extend .icon-arrow-line-left:before;
        position: absolute;
        left: 50%;
        top: 50%;
        text-indent: 0;
        font-size: rem-calc(22);
        color: $color-content;
        transform: translate(-50%, -50%);
        transition: color 0.25s;
      }

      &:hover{
        background-color: $color-dark;

        &::before{
          color: #FFFFFF;
        }
      }

      &.disabled{
        box-shadow: none;
        cursor: default;
      }
      &.disabled:hover{
        background-color: #fff;
      }
      &.disabled::before{
        color: scale-color($color-content, $lightness: 70%);
      }
      &.disabled:hover::before{
        color: scale-color($color-content, $lightness: 70%);
      }
    }

    .owl-next{
      &::before{
        @extend .icon-arrow-line-right:before;
      }
    }
  }
}
