/* ==========================================================================
  table.scss
========================================================================== */



// Responsive table
.table-container {
  width: 100%;
  margin-bottom: rem-calc(40);
  overflow: hidden;
  overflow-x: auto;

  th {
    p {
      margin: 0;
    }
  }

  td {
    font-size: rem-calc(17);

    p {
      margin: 0;
      font-size: rem-calc(17);
    }
  }
}

table {
  position: relative;
  margin-bottom: rem-calc(10);
  width: 100% !important;
  border: 1px solid #e2e2e2;


  tr {
    &:nth-child(even) td {
      background-color: #f7f7f7;
    }

    &:last-child td {
      border-bottom: none;
    }
  }

  th {
    background-color: #f0f0f0;
    font-size: rem-calc(21);
    color: darken($color-alt, 5%);
    font-weight: normal;
  }

  td, th {
    padding: rem-calc(8 12);
    border-right: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    line-height: 1.5;

    &[colspan] {
      padding-top: rem-calc(15);
      padding-bottom: rem-calc(15);
    }

    &:last-child {
      border-right: none;
    }

    @media (max-width: 767px) {
      font-size: rem-calc(14);
      line-height: rem-calc(18);
    }
  }
}
