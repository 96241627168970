/* ==========================================================================
to_discover.scss
========================================================================== */

.a-decouvrir {
  //@include flex-grid-row;
  width: 100%;
  //justify-content: flex-start;
  padding: 0 .625rem;
  h3 {
    @include flex-grid-column(12);
    margin: 0 0 1em 0;
    padding: 0 !important;
    font-family: 'ActaDisplay';
    font-size: em(26);
    line-height: lh(26,39);
    font-weight: 900;
    font-style: italic;
    color: $color-dark;
    @include breakpoint(medium) {
      font-size: em(30);
    }
    @include breakpoint(large) {
      font-size: em(35);
    }
  }

  .featured {
    //@include flex-grid-column(12);
    padding: 0;
    margin: 0 -.625rem;
    //flex: none;.9375rem
    @include breakpoint(medium) {
      margin: 0 -.9375rem;
    }
    @include breakpoint(large) {
      margin: 0 -1.875rem;
    }
  }
  .row {
    //@include flex-grid-row;
    
    max-width: 100%;
    .preview-article {
      @include flex-grid-column(12);
      padding-top: 0;
      @include breakpoint(medium) {
        @include flex-grid-column(6);
      }
      @include breakpoint(large) {
        @include flex-grid-column(4);
      }

      &:first-child {
        //padding-left: 0;
      }
      &:last-child {
        //padding-right: 0;
      }

      &::before {
        right: rem-calc(-1);
      }
      &::after {
        content:none;
      }
    }
  }

  .view-articles{
    .views-row{
      @extend .preview-article;
      @include flex-grid-column(4);
    }
  }
}
