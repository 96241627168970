/* ==========================================================================
articles.scss
========================================================================== */

.featured--newsletter,
.last-articles__content,
.categ-level-1 .articles-categorie,
.categ-level-2 .articles-categorie{
  @include flex-grid-row;
  justify-content: space-between;
}

.preview-article {
  position: relative;
  padding-top: rem-calc(30);
  padding-bottom: rem-calc(30);

  .featured--three &,
  .featured--four &,
  .featured--five &,
  .featured--six &,
  .a-decouvrir & {
    @include flex-grid-column(12);

    @include breakpoint(medium) {
      @include flex-grid-column(30%, 0);
    }

    &.fullwidth{
      @include flex-grid-row;
      @include flex-grid-size(100%);
    }
  }

  .featured--newsletter &,
  .last-articles__content &,
  .categ-level-1 .articles-categorie &:not(.fullwidth--small),
  .categ-level-2 .articles-categorie &:not(.fullwidth--small, .highlight){
    @include flex-grid-column(12);

    @include breakpoint(medium) {
      @include flex-grid-column(47%, 0);
    }
  }

  .featured--six .mini &,
  .featured--five .mini &{
    @include flex-grid-row;
    @include flex-grid-size(100%);
    padding-top: rem-calc(25);
    padding-bottom: rem-calc(25);
  }

  &.highlight{
    min-width: 100%;
    color: $color-dark;
  }

  .special-abonne &{
    /*min-height: rem-calc(493);*/
      @include flex-grid-column(12);

      @include breakpoint(medium) {
        @include flex-grid-column(30%, 1.5%);
      }
  }

  // Vertical Borders
  @include breakpoint(large) {
    .featured--newsletter &:first-child::before,
    .featured--six &:not(:first-child):nth-child(-n+3)::before,
    .featured--five &:nth-child(-n+2)::before,
    .featured--four &:nth-child(n+2):not(:last-child)::before,
    .featured--three &:nth-child(-n+2)::before,
    .last-articles__content &:not(:nth-child(2n+2))::before,
    .a-decouvrir &:not(:last-child)::before,
    .articles-categorie-and-others .mini:nth-child(2n+1) &::before {
      content: "";
      position: absolute;
      background: $color-divider;
      height: calc(100% - 60px);
      width: 1px;
      top: rem-calc(30);
      right: -8.5%;
    }

    .featured--newsletter &:first-child::before,
    .last-articles__content &:not(:nth-child(2n+2))::before{
      right: -6.5%;
    }

    .special-abonne &::before{
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: $color-divider;

    .categ-level-2 &{
      left: 0;
      width: calc(100% - 0px);
    }
  }

  .last-articles__content &:nth-last-child(-n+2)::after,
  .a-decouvrir &::after,
  .articles-categorie-and-others .mini:nth-last-child(-n+2) &::after,
  .page-page-auteur &:last-child::after,
  .special-abonne &::after,
  .featured--six &::after{

    @include breakpoint(medium) {
      display: none;
    }
  }

  .last-articles__content &:last-child::after,
  .special-abonne &:last-child::after{
    display: none;
  }

  .special-abonne &::after{
    opacity: 0.22;
  }

  .featured--six &.fullwidth::after,
  .featured--six .mini &:not(:last-child)::after{
    display: block;
  }

  &.fullwidth{
    @include flex-grid-row;
    @include flex-grid-size(100%);

    @include breakpoint(medium) {
      @include flex-grid-size(100%);
    }
  }

  &.fullwidth--small{
    @include flex-grid-row;
    @include flex-grid-size(100%);

    @include breakpoint(medium) {
      @include flex-grid-size(100%);
    }
  }

  .articles-categorie-and-others .mini &{
    @include flex-grid-row;
  }

  .mini &::before {
    display: none;
  }

  &__header {
    @include hover_animation;

    figure {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: rem-calc(200);
      margin-bottom: rem-calc(20);
      background: $color-light;
      background-repeat: repeat;
      background-position: left top;
      /*height: rem-calc(266);*/

      @include breakpoint(medium) {
        height: rem-calc(272);
      }

      @at-root {

        .boite-a-outil & {
          background-color: lighten($color-toolbox, 24%);
        }

        .bien-manger & {
          background-color: lighten($color-goodfood, 24%);
        }

        .cuisines-et-recettes & {
          background-color: lighten($color-recipes, 24%);
        }

        .se-soigner & {
          background-color: lighten($color-healthcare, 24%);
        }

        .bien-etre & {
          background-color: lighten($color-healthy, 24%);
        }

        .forme & {
          background-color: lighten($color-shape, 24%);
        }
      }

      .fullwidth & {
        height: rem-calc(410);
        margin-bottom: 0;
      }

      .fullwidth--small & {
        /*height: auto;*/
        max-height: rem-calc(200);
        margin-bottom: rem-calc(20);

        @include breakpoint(medium) {
          margin-bottom: 0;
        }
      }

      .highlight &{
        //height: rem-calc(410);
        margin-bottom: 0;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        max-width: 100%;
        max-height: auto;
        min-height: 100%;
        min-width: 101%;
        height: auto;
        width: auto;
        transform: translate(-50%, -50%);

        @include breakpoint(medium) {
          //max-height: rem-calc(266);
        }

        @include breakpoint(small only) {
          max-width: 100% !important;
        }

        .fullwidth & {
          max-height: rem-calc(410);

          @include breakpoint(small only) {
            max-width: rem-calc(630) !important;
          }
        }
      }

      .mini &{
        height: rem-calc(90);
        margin-bottom: 0;
      }
    }

    .fullwidth--small & {
      @include flex-grid-column(12, 0);
      padding-right: 0;
      padding-left: 0;

      @include breakpoint(medium) {
        @include flex-grid-size(4);
      }
    }

    .fullwidth & + .column {
      display: flex;
      padding-left: 0;
    }

    .fullwidth & {
      @include flex-grid-column(12, 0);

      @include breakpoint(medium) {
        @include flex-grid-size(8);
      }
    }

    .articles-categorie-and-others .mini &,
    .featured--five .mini &,
    .featured--six .mini &{
      @include flex-grid-size(4);
    }
  }

  &__private{
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: rem-calc(82);
    height: rem-calc(82);
    background: rgba(255, 255, 255, 0.93) url(../img/icon_chilli_pepper.png) no-repeat center center;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  &__title,
  .views-field-title {
    /*display: inline-block;*/
    display: block;
    margin-bottom: rem-calc(15);
    font-size: rem-calc(36);
    line-height: rem-calc(32);
    color: $color-dark;
    transition: color 0.25s;

    &:hover{
      color: $color-main;

      @at-root {

        .boite-a-outil & {
          color: darken($color-toolbox, 20%);
        }

        .bien-manger & {
          color: darken($color-goodfood, 20%);
        }

        .cuisines-et-recettes & {
          color: darken($color-recipes, 20%);
        }

        .se-soigner & {
          color: darken($color-healthcare, 20%);
        }

        .bien-etre & {
          color: darken($color-healthy, 20%);
        }

        .forme & {
          color: darken($color-shape, 20%);
        }
      }

      .node-dossiers &{
        color: $color-dark;
      }
    }

    .special-abonne &{
      color: #FFFFFF !important;
      transition: opacity 0.35s $easeOutExpo;

      &:hover{
        opacity: 0.7;
      }
    }

    a{
      color: $color-dark;
      transition: color 0.25s;

      &:hover{
        color: $color-main;

        @at-root {

          .boite-a-outil & {
            color: darken($color-toolbox, 20%);
          }

          .bien-manger & {
            color: darken($color-goodfood, 20%);
          }

          .cuisines-et-recettes & {
            color: darken($color-recipes, 20%);
          }

          .se-soigner & {
            color: darken($color-healthcare, 20%);
          }

          .bien-etre & {
            color: darken($color-healthy, 20%);
          }

          .forme & {
            color: darken($color-shape, 20%);
          }
        }
      }
    }

    .custom_search_results &{
      display: block;
      font-size: rem-calc(27);
      line-height: rem-calc(27);
    }

    .mini & {
      margin-bottom: rem-calc(3);
      font-size: rem-calc(20);
      line-height: rem-calc(20);
    }

    .fullwidth & {
      font-size: rem-calc(40);
      line-height: rem-calc(38);

      @include breakpoint(medium) {
        font-size: rem-calc(53);
        line-height: rem-calc(44);
      }
    }

    .highlight & {
      margin-bottom: rem-calc(13);
      font-size: rem-calc(36);
      line-height: rem-calc(30);

      @include breakpoint(medium) {
        font-size: rem-calc(55);
        line-height: rem-calc(45);
      }

      .node-dossiers &{
        margin-bottom: rem-calc(17);
      }
    }
  }

  &__chapo{
    margin-bottom: rem-calc(12);
    font-size: rem-calc(19);
    line-height: rem-calc(25);
  }

  &__content {

    p,
    &__chapo {
      font-family: 'LemonSans';
      font-weight: 300;
      font-size: rem-calc(18);
      line-height: rem-calc(23);
      color: $color-content;

      .special-abonne &{
        color: #FFFFFF;
      }
    }

    .fullwidth & {
      @include flex-grid-column(12);
      padding-top: rem-calc(23);
      padding-bottom: rem-calc(30);
      background: $color-light;
      flex: 1 0 auto;

      @include breakpoint(medium) {
        @include flex-grid-column(4, 0);
        padding-top: rem-calc(40);
        padding-bottom: rem-calc(40);
        padding-left: rem-calc(38);
        padding-right: rem-calc(22);
      }
    }

    .fullwidth--small &{
        @include flex-grid-column(12, 0);

        @include breakpoint(medium) {
          @include flex-grid-size(8);
          padding-left: rem-calc(30);
        }
    }

    .highlight &{
      position: relative;
      padding: rem-calc(40 40);
      background-color: $color-light;

      .node-dossiers &{
        margin-bottom: rem-calc(30);
      }
    }

    /*.categ-level-2 .articles-categorie .fullwidth--small &{
      padding-left: rem-calc(30);
    }*/

    .articles-categorie-and-others .mini &,
    .featured--five .mini &,
    .featured--six .mini &{
      @include flex-grid-size(8);
      padding-left: rem-calc(20);
    }
  }

  &__category {
    display: inline-block;
    font-family: 'ActaDisplay', Georgia, serif;
    font-size: rem-calc(15);
    font-weight: 500;
    font-style: italic;
    color: $color-content;
    transition: color 0.25s;
    cursor: pointer;
    position: relative;

    &::after{
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: rem-calc(2);
      background: $color-content;
      opacity: .8;
      transform: scaleX(0);
      transform-origin: 100% 0;
      transition: transform .3s $easeOutQuart;
    }

    &:hover::after{
      transform: scaleX(1);
      transform-origin: 0 0;
    }

    &.boite-a-outil {
      color: $color-toolbox;

      &::after{ background: $color-toolbox; }
    }

    &.bien-manger {
      color: $color-goodfood;

      &::after{ background: $color-goodfood; }
    }

    &.cuisines-et-recettes {
      color: $color-recipes;

      &::after{ background: $color-recipes; }
    }

    &.se-soigner {
      color: $color-healthcare;

      &::after{ background: $color-healthcare; }
    }

    &.bien-etre {
      color: $color-healthy;

      &::after{ background: $color-healthy; }
    }

    &.forme {
      color: $color-shape;

      &::after{ background: $color-shape; }
    }

    .mini &{
      font-size: rem-calc(13);
      line-height: 1;
    }

    @at-root{
      .page-page-auteur &{
        color: $color-alt;

        &:hover{
          color: $color-dark;
        }
      }
    }

    .special-abonne &{
      color: #FFFFFF !important;
      opacity: 0.7;
      transition: opacity 0.25s $easeOutQuad;

      &:hover{
        opacity: 1;
      }
    }
  }
}

.mini{
  @include flex-grid-column(100%, 0);
  padding-top: rem-calc(5);

  .featured--five &,
  .featured--six &{
    @include breakpoint(medium) {
      @include flex-grid-size(30%);
    }
  }
}

.articles-categorie{
  padding-left: 0;
}

.throbber{
  position: relative;
  margin-top: rem-calc(22);

  &::before{
    position: absolute;
    left: 50%;
    top: 0;
    content:'';
    width: rem-calc(50);
    height: rem-calc(50);
    background: transparent url(../img/throbber.gif) no-repeat center center;
    transform: translateX(-50%);
  }
}

.featured, .content {
  .left {
    margin: 0;
  }
}
