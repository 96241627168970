/* ==========================================================================
    preferences.scss - affichage connecté pour un user sur page préférences compte
   ========================================================================== */

.page-user-mes-preferences {
  .header__content__social,
  .header__content__user {
    display: none;
  }
  #sticky-header {
    display: none;
  }
  .region-navigation {
    display: none;
  }
  section[role=main] {
    min-height: auto;
    padding-top: rem-calc(16);

    &::before {
      display: none;
    }
  }
  .breadcrumb {
    display: none;
  }
  .page-title {
    margin-bottom: 0;
  }

  .content-subscription {
    .intro-forms {
      font-size: rem-calc(22);
    }

  }

  #choosit-subsribe-form .form-item.form-type-checkbox {
    padding-left: 0;
    padding-right: 0;
    align-items: baseline;

    [type=checkbox] {
      margin: 0;
      margin-right: rem-calc(16);
    }

    label {
      font-size: rem-calc(18);
      line-height: 1;

      p {
        margin-bottom: 0;
      }
    }
  }

  .region-pre-footer,
  .container--footer {
    display:  none;
  }
}