/* ==========================================================================
  buttons.scss
========================================================================== */

.button {
  background: $color-main;
  box-shadow: inset rem-calc(0 0 0 -2) $color-dark;
  color: #ffffff;
  padding: rem-calc(12 22);
  margin: 0;
  border-radius: rem-calc(3);
  font-family: 'LemonSans';
  font-size: rem-calc(16);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: rem-calc(1);
  border: none;
  transition: box-shadow .40s $easeOutQuad;

  &:hover,
  &:focus {
    background: $color-main;
    box-shadow: inset rem-calc(300 0 0 0) $color-dark;
  }

  &.outlined {
    background: none;
    color: $color-dark;
    box-shadow: inset 0 0 0 0 $color-main, inset rem-calc(0 0 0 2) $color-dark;
    transition: box-shadow .40s $easeOutQuad, color .25s $easeOutQuad;

    &:hover,
    &:focus {
      color: #ffffff;
      box-shadow: inset rem-calc(300 0 0 0) $color-main, inset rem-calc(0 0 0 2) $color-dark;
    }

    &.white {
      background: none;
      color: #ffffff;
      box-shadow: inset rem-calc(0 0 0 -2) #FFFFFF, inset rem-calc(0 0 0 2) #ffffff;

      &:hover,
      &:focus {
        color: $color-dark;
        box-shadow: inset rem-calc(300 0 0 0) #FFFFFF, inset rem-calc(0 0 0 2) #ffffff;
      }
    }
  }

  &.large {
    padding: rem-calc(12 70);
    font-size: rem-calc(16);
  }

  &.white {
    background: #ffffff;
    color: $color-dark;

    &:hover,
    &:focus {
      color: #ffffff;
    }
  }
}
