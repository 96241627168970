/* ==========================================================================
  article.scss
=========================================================================== */

.article {

  &__type {
    position: absolute;
    left: rem-calc(25);
    top: 0;
    display: inline-block;
    padding: rem-calc(8 10);
    font-size: rem-calc(13);
    letter-spacing: rem-calc(1);
    line-height: 1;
    text-transform: uppercase;
    color: $color-dark;
    background: #ffffff;
    border-bottom-left-radius: rem-calc(2);
    border-bottom-right-radius: rem-calc(2);

    .region-pre-footer &{
      @include breakpoint(medium) {
        top: rem-calc(60);
      }
    }
  }

}



/* PAGE ARTICLE DEVELOPPEE + DOSSIER */

.node-type-articles,
.node-type-dossiers {
  section[role=main]{
    H1{
      margin: auto;
      margin-bottom: rem-calc(34);
    }
  }

  .list-articles{
    @include flex-grid-row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .pub-long{
    flex: 1 100%;
  }
}

.node-type-dossiers {
  .list-articles {
    @include flex-grid-size(12);
    margin-left: 0;

    @include breakpoint(xlarge) {
      @include flex-grid-size(8);
    }
  }
}

.node-articles {
  @include flex-grid-row;

  .infos-article {
    @include flex-grid-column(12);
    @include clearfix;
    align-items: baseline;
    margin-bottom: rem-calc(46);
    font-size: rem-calc(20);
    line-height: rem-calc(25);
    text-align: center;

    .article-auteur {
      color: $color-main;

      @at-root {

        .boite-a-outil & {
          color: $color-toolbox;
        }

        .bien-manger & {
          color: darken($color-goodfood, 6%);
        }

        .cuisines-et-recettes & {
          color: $color-recipes;
        }

        .se-soigner & {
          color: $color-healthcare;
        }

        .bien-etre & {
          color: $color-healthy;
        }

        .forme & {
          color: $color-shape;
        }
      }
    }

    .date-publication {
      font-family: 'ActaDisplay';
      font-size: rem-calc(15);
      font-weight: 500;
      font-style: italic;

      &::before {
        position: relative;
        content: '|';
        margin: rem-calc(13);
        font-family: 'LemonSans';
        font-weight: 500;
        font-size: rem-calc(20);
        line-height: rem-calc(25);
        font-style: normal;
      }

      & + .edition-abonne{
        &::before {
          position: relative;
          content: '|';
          margin: rem-calc(13);
          font-family: 'LemonSans';
          font-weight: 500;
          font-size: rem-calc(20);
          line-height: rem-calc(25);
          font-style: normal;
        }
      }
    }
  }

  .visuel{
    position: relative;
    margin: auto;
    margin-bottom: rem-calc(60);
    width: 100%;
    img{
      width: 100%;
      margin: auto;
    }
  }

  .body{
    margin: rem-calc(0);
    padding: rem-calc(0 0 21 0);
    font-size: rem-calc(20);
    line-height: 1;
    font-weight: 300;
    border-bottom: rem-calc(1) solid $color-divider;

    p{
      line-height: rem-calc(26);
    }

    strong {
      font-size: rem-calc(21);
    }

    & > p:first-child::first-letter{
      float: left;
      margin: rem-calc(0 13 0 0);
      font-family: 'ActaDisplay';
      font-size: rem-calc(94);
      line-height: rem-calc(94);
      font-style: italic;
      text-transform: uppercase;

      @at-root {

        .boite-a-outil & {
          color: $color-toolbox;
        }

        .bien-manger & {
          color: $color-goodfood;
        }

        .cuisines-et-recettes & {
          color: $color-recipes;
        }

        .se-soigner & {
          color: $color-healthcare;
        }

        .bien-etre & {
          color: $color-healthy;
        }

        .forme & {
          color: $color-shape;
        }
      }
    }

    a{
      @at-root {

        .boite-a-outil & {
          color: $color-toolbox;
        }

        .bien-manger & {
          color: darken($color-goodfood, 22%);
        }

        .cuisines-et-recettes & {
          color: $color-recipes;
        }

        .se-soigner & {
          color: $color-healthcare;
        }

        .bien-etre & {
          color: $color-healthy;
        }

        .forme & {
          color: $color-shape;
        }
      }
    }

  }


  // When article is locked to prevent float bug
  .locked + .a-lire-aussi{
    float: none;
    margin-bottom: rem-calc(30);
  }

  .chapo{
    padding: rem-calc(0 0 21 0);
    font-size: rem-calc(28);
    line-height: rem-calc(37);

    p{
      line-height: inherit;
    }
    @include breakpoint(medium) {
       font-size: rem-calc(35);
    }
  }

  .lettrine{
    float: left;
    margin: rem-calc(0 13 0 0);
    font-family: 'ActaDisplay';
    font-size: rem-calc(94);
    line-height: rem-calc(94);
    font-style: italic;
    text-transform: uppercase;

    @at-root {

      .boite-a-outil & {
        color: $color-toolbox;
      }

      .bien-manger & {
        color: $color-goodfood;
      }

      .cuisines-et-recettes & {
        color: $color-recipes;
      }

      .se-soigner & {
        color: $color-healthcare;
      }

      .bien-etre & {
        color: $color-healthy;
      }

      .forme & {
        color: $color-shape;
      }
    }
  }

  .content{
    @include flex-grid-row;
    justify-content: space-between;
    align-items: stretch;
  }

  .right {
    position: relative;
  }

  .article__pub + .articles-conseilles {
    @include breakpoint(large up) {
      position: absolute;
      bottom: 0;
      right: rem-calc(15);
    }
  }
}

.a-lire-aussi{
    width: 100%;
    margin: rem-calc(11 25 15 0);
    padding: rem-calc(25 25 19 25);
    background-color: $color-divider;

    @include breakpoint(medium) {
      float: left;
      max-width: rem-calc(275);
    }

    @include breakpoint(xlarge) {
      margin-left: rem-calc(-50);
    }



    & > span{
      display: block;
      margin: rem-calc(0 0 14 0);
      font-family: 'ActaDisplay';
      font-size: rem-calc(25);
      line-height: rem-calc(29);
      font-weight: 900;
      font-style: italic;

      @at-root {

        .boite-a-outil & {
          color: $color-toolbox;
        }

        .bien-manger & {
          color: $color-goodfood;
        }

        .cuisines-et-recettes & {
          color: $color-recipes;
        }

        .se-soigner & {
          color: $color-healthcare;
        }

        .bien-etre & {
          color: $color-healthy;
        }

        .forme & {
          color: $color-shape;
        }
      }
    }

    a{
      display: block;
      margin: rem-calc(0 0 12);
      font-family: 'LemonSans';
      font-weight: normal;
      font-size: rem-calc(20);
      line-height: rem-calc(21);
      color: $color-dark !important;
      background: url(../img/arrow_right_content.svg) no-repeat rem-calc(-16) rem-calc(5);
      background-size: rem-calc(16) auto;
      transition: padding 0.25s $easeOutQuad, background-position 0.25s $easeOutQuad;

      &:hover{
        padding-left: rem-calc(25);
        background-position: left rem-calc(5);
      }
    }
  }

.node-dossiers{
  @include flex-grid-row;

  .article-number{
    margin: auto;
    margin-bottom: rem-calc(50);
    font-family: 'ActaDisplay';
    font-size: rem-calc(17);
    line-height: rem-calc(19);
    font-weight: 500;
    font-style: italic;
  }

  .visuel{
    position: relative;
    margin: auto;
    margin-bottom: rem-calc(60);

    img{
      margin: auto;
    }
  }

  .chapo{
    margin: rem-calc(0 0 44 0);
    padding: rem-calc(0 0 21 0);
    font-size: rem-calc(35);
    line-height: rem-calc(37);
    border-bottom: rem-calc(1) solid $color-divider;

    p{
      line-height: inherit;
    }
  }

  .infos-article {
    @include flex-grid-column(12, 0);
    @include clearfix;
    align-items: baseline;
    margin-bottom: rem-calc(12);
    font-size: rem-calc(20);
    line-height: rem-calc(25);
    text-align: left;

    .article-auteur {
      color: $color-main;

      @at-root {

        .boite-a-outil & {
          color: $color-toolbox;
        }

        .bien-manger & {
          color: darken($color-goodfood, 6%);
        }

        .cuisines-et-recettes & {
          color: $color-recipes;
        }

        .se-soigner & {
          color: $color-healthcare;
        }

        .bien-etre & {
          color: $color-healthy;
        }

        .forme & {
          color: $color-shape;
        }
      }
    }

    .date-publication {
      font-family: 'ActaDisplay';
      font-size: rem-calc(15);
      font-weight: 500;
      font-style: italic;

      &::before {
        position: relative;
        content: '|';
        margin: rem-calc(13);
        font-family: 'LemonSans';
        font-weight: 500;
        font-size: rem-calc(20);
        line-height: rem-calc(25);
        font-style: normal;
      }
    }
  }

}

// ### Section d'articles génériques
// Container principal (full width)
.articles{

  // Page centrale
  &__content{
    @include flex-grid-row;
    justify-content: space-between;
  }

  // Colonnes
  // Gérées par .left & .right dans le tpl

}


/* SPECIAL SUBSCRIBER */

.subscribers-only{
  vertical-align: top;
  width: 100%;
  margin: 0 auto rem-calc(51);
  padding: 0;
  box-shadow: rem-calc(3 3 30 1) $shadow-soft;
  background: #FFFFFF;
  box-shadow: rem-calc(0 0 0 13) #FFFFFF;
  border: rem-calc(13) solid transparent;
  border-image: url(../img/bg_pattern_stripes.png) 13 round;

  h3{
    margin: rem-calc(0);
    padding: rem-calc(75 0 50 0);
    padding-left: 9%;
    padding-right: 9%;
    font-family: 'ActaDisplay';
    font-size: rem-calc(25);
    line-height: rem-calc(26);
    font-weight: 900;
    font-style: italic;
    text-align: center;
    background: #f1f3f4 url(../img/bg_block_subscriber.png) no-repeat left top;

    @include breakpoint(large) {
      padding-left: 25%;
      padding-right: 25%;
      font-size: rem-calc(34);
      line-height: rem-calc(36);
    }
  }

  .blocks-subscribed-and-not{
    @include flex-grid-row;
    padding: rem-calc(45 0 21 0);
    line-height: 1;

    @include breakpoint(large) {
      padding-bottom: rem-calc(60);
    }

    .already-subscribed,
    .not-subscribed{
      position: relative;
      @include flex-grid-column(12);
      align-content: space-between;
      padding-bottom: rem-calc(73);
      text-align: center;
      font-size: rem-calc(18);
      line-height: rem-calc(23);
      color: $color-content;
      font-weight: 300;

      @include breakpoint(large) {
        @include flex-grid-column(6, 100);
        padding-bottom: rem-calc(53);
      }

      .title{
        display: block;
        margin-bottom: rem-calc(11);
        font-size: rem-calc(20);
        line-height: rem-calc(23);
        text-transform: uppercase;
        font-weight: 500;
        color: $color-dark;
      }

      p{
        line-height: rem-calc(23);
      }
    }

    .already-subscribed{
      margin-bottom: rem-calc(30);
      border-bottom: rem-calc(1) solid $color-divider;

      @include breakpoint(large) {
        margin-bottom: (0);
        padding-bottom: rem-calc(0);
        border-right: rem-calc(1) solid $color-divider;
        border-bottom: 0;
      }
    }
  }

  .button{
    position: absolute;
    left: 50%;
    bottom: rem-calc(30);
    align-self: flex-end;
    min-width: rem-calc(230);
    margin: 0;
    box-shadow: inset rem-calc(0 0 0 -2) $color-dark, rem-calc(0 10 40 0) $shadow-soft;
    transform: translateX(-50%);

    &:hover{
      box-shadow: inset rem-calc(300 0 0 0) $color-dark, rem-calc(0 10 40 0) $shadow-soft;
    }

    @include breakpoint(large) {
      bottom: 0;
    }
  }
}
