/* ==========================================================================
  petition.scss
=========================================================================== */

.node-type-petition {
  .container {
    max-width: rem-calc(960);
    padding: rem-calc(0 16);

    h1 {
      margin-bottom: rem-calc(40);
    }
  }

  .petition {
    &__wrapper {
      @include breakpoint(768) {
        display: flex;
        justify-content: flex-start;
        flex-direction: row-reverse;
      }

      .block-views {
        padding: 0;
        margin: 0;
        height: auto;
        flex: auto;
        max-width: none;

        @include breakpoint(768) {
          margin-left: rem-calc(50);
        }

        &:before {
          display: none;
        }

        .view-petition-counter {
          width: rem-calc(200);

          @include breakpoint(768 down) {
            position: fixed;
            bottom: 0;
            left: 0;
            background: #fff;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            z-index: 2;
            border-top: rem-calc(2) solid $color-main;

            &.hidden {
              display: none;
            }
          }

          &--sticky {
            @include breakpoint(768) {
              position: fixed;
              top: rem-calc(60);
            }
          }
        }

        .button {
          font-size: rem-calc(22);
          margin: rem-calc(10 0 4);
          box-shadow: none;
          transition: background-color 0.3s ease;

          @include breakpoint(768) {
            margin: rem-calc(0 0 20 0);
          }

          &:hover {
            background-color: darken($color-main, 5%);
          }
        }

        .view-content {
          font-size: rem-calc(18);
          margin-bottom: rem-calc(10);

          @include breakpoint(768) {
            font-size: rem-calc(22);
          }

          .views-field-serial {
            text-align: center;

            strong {
              font-size: rem-calc(24);
              font-weight: 500;
              color: $color-main;
            }
          }
        }
      }
    }
    &__content {
      @include breakpoint(768) {
        width: calc(100% - #{rem-calc(250)});
      }

      .webform-client-form {
        margin-bottom: rem-calc(40);
        padding: rem-calc(10 20 20 20);
        background: #f1f1f1;

        .form-item {
          margin: rem-calc(16 0);
        }

        label {
          font-size: rem-calc(18);
        }

        .webform-component-markup p {
          margin-left: 0;
          font-size: rem-calc(15);
        }

        .chosen-container {
          font-size: rem-calc(18);
        }

        .webform-component-boolean {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: center;

          .form-checkbox {
            margin: rem-calc(0 10 0 0);
          }
        }

        .form-actions .form-submit {
          font-size: rem-calc(26);
        }
      }
    }
  }

  .footer {
    padding-top: rem-calc(40);

    // Crappy hack, sorry not sorry
    #block-block-6 {
      padding-bottom: 0;
      border-bottom: none;
    }

    #block-block-5,
    #block-block-8 {
      display: none;
    }
  }
}
