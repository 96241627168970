/* ==========================================================================
summary.scss
========================================================================== */

.summary-fix{
  width: calc(100% - 30px);
  margin: 0 auto;
  margin-top: rem-calc(13);
  margin-bottom: rem-calc(60);
  padding: rem-calc(17 7 5 7);
  box-shadow: rem-calc(3 3 30 1) $shadow-soft;
  background: #FFFFFF;
  /*box-shadow: rem-calc(0 0 0 13) lighten($color-main, 20%);*/
  box-shadow: rem-calc(0 0 0 13) lighten($color-main, 20%);
  border: rem-calc(13) solid transparent;
  border-image: url(../img/bg_pattern_stripes.png) 13 round;
  border-image-outset: 1;

  @at-root {
    .boite-a-outil &,
    .section-les-aliments-a-la-loupe & {
      box-shadow: rem-calc(0 0 0 13) lighten($color-toolbox, 20%);
    }

    .bien-manger & {
      box-shadow: rem-calc(0 0 0 13) lighten($color-goodfood, 20%);
    }

    .cuisines-et-recettes & {
      box-shadow: rem-calc(0 0 0 13) lighten($color-recipes, 20%);
    }

    .se-soigner & {
      box-shadow: rem-calc(0 0 0 13) lighten($color-healthcare, 20%);
    }

    .bien-etre & {
      box-shadow: rem-calc(0 0 0 13) lighten($color-healthy, 20%);
    }

    .forme & {
      box-shadow: rem-calc(0 0 0 13) lighten($color-shape, 20%);
    }
  }

  h3{
    position: relative;
    margin: auto;
    margin-bottom: rem-calc(10);
    padding: rem-calc(0 0 20 0);
    font-family: 'ActaDisplay';
    font-size: rem-calc(25);
    line-height: rem-calc(29);
    font-weight: 900;
    font-style: italic;
    color: $color-dark;
    text-align: center;

    &::before{
      position: absolute;
      left: 50%;
      bottom: 0;
      content: '';
      width: rem-calc(40);
      height: 0;
      border-bottom: rem-calc(1) solid $color-dark;
      transform: translateX(-50%);
    }
  }

  &__line{
    position: relative;
    @include flex-grid-row;
    flex-wrap: nowrap;
    margin: rem-calc(0);
    padding: rem-calc(16 0 7 0);
    border-bottom: rem-calc(1) solid $color-divider;
    transition: box-shadow 0.25s $easeOutQuad;
    box-shadow: inset rem-calc(0 0 0 -2) #FFFFFF, inset rem-calc(0 0 0 2) #ffffff;

    &:hover{
      box-shadow: inset rem-calc(300 0 0 0) $color-light, inset rem-calc(0 0 0 2) #ffffff;
    }

    &:last-child{
      margin: 0;
      border: none;
    }

    > a{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .summary-place{
    flex: 0 0 rem-calc(65);
    padding-right: rem-calc(20);
    /*width: rem-calc(54);*/
    font-family: 'ActaDisplay';
    font-size: rem-calc(40);
    line-height: rem-calc(45);
    font-weight: 500;
    text-align: right;
    color: $color-dark;
    font-style: italic;
  }

  .title{
    font-size: rem-calc(20);
    line-height: rem-calc(20);

    a{
      color: $color-dark;
    }
  }
}
