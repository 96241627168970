/* ==========================================================================
  standard.scss
========================================================================== */

// Node
.node-page-standard{
  @include flex-grid-row;
  padding-top: rem-calc(100);

  .right {
    position: relative;
  }

  .article__pub + .articles-conseilles {
    @include breakpoint(large up) {
      position: absolute;
      bottom: 0;
      right: rem-calc(15);
    }
  }
}


// Standard Styles
.body{
  @include standard_styles;
}

.container{
  width: 100%;
  max-width: rem-calc(1200);
  margin: auto;

  @include breakpoint(medium) {
    padding-left: rem-calc(15);
    padding-right: rem-calc(15);
  }

  @include breakpoint(xlarge) {
    padding-left: rem-calc(0);
    padding-right: rem-calc(0);
  }
}

section[role="main"]{
  position: relative;

  &::before{
    content: '';
    position: absolute;
    z-index: -2;
    right: 50%;
    top: 0;
    width: 100%;
    max-width: rem-calc(1600);
    height: rem-calc(330);
    background: darken($color-light, 1.5%) url(../img/bg_pattern_home.png) repeat left top; // Default = grey
    pointer-events: none;
    transform: translateX(50%);

    @include breakpoint(medium) {
      height: rem-calc(426);
    }

    .page-home &{
      height: rem-calc(530);
    }

    .section-les-aliments-a-la-loupe &{
      background-color: lighten($color-toolbox, 34%);
      background-image: url(../img/bg_pattern_boiteaoutil.png);
    }

    .page-page-auteur &,
    .page-user &,
    .page-search &,
    .page-recherche-aliments &{
      height: rem-calc(320);
    }

    .page-user &{
      height: rem-calc(220);
    }

    .node-type-aliments &,
    .page-node-11 &{
      display: none;
    }

    .node-type-page-standard &{
      height: rem-calc(220);

      @include breakpoint(medium) {
        height: rem-calc(426);
      }
    }

  }

  &.boite-a-outil {
    &::before{
      background-color: lighten($color-toolbox, 34%);
      background-image: url(../img/bg_pattern_boiteaoutil.png);
    }
  }

  &.bien-manger {
    &::before{
      background-color: lighten($color-goodfood, 27%);
      background-image: url(../img/bg_pattern_bienmanger.png);
    }
  }

  &.cuisines-et-recettes {
    &::before{
      background-color: lighten($color-recipes, 29%);
      background-image: url(../img/bg_pattern_recettes.png);
    }
  }

  &.se-soigner {
    &::before{
      background-color: #FFFFFF;
      background-image: url(../img/bg_pattern_soigner.png);
    }
  }

  &.bien-etre {
    &::before{
      background-color: lighten($color-healthy, 33%);
      background-image: url(../img/bg_pattern_bienetre.png);
    }
  }

  &.forme {
    &::before{
      background-color: lighten($color-shape, 33%);
      background-image: url(../img/bg_pattern_forme.png);
    }
  }

  h1{
    margin: auto;
    max-width: rem-calc(960);
    margin-bottom: rem-calc(20);
    text-align: center;
  }
}

/*.page-taxonomy{

  h1,
  &:not(.section-les-aliments-a-la-loupe) h1 {
    font-family: 'ActaDisplay';
    font-size: rem-calc(65);
    line-height: rem-calc(70);
    font-weight: 900;
    font-style: italic;
    color: $color-dark;
  }

  h2{
    max-width: rem-calc(960);
    margin: auto;
    margin-bottom: rem-calc(45);
    font-family: 'LemonSans';
    font-size: rem-calc(35);
    line-height: rem-calc(36);
    font-weight: 200;
    text-align: center;
  }
}*/

body:not(.node-type-articles) .breadcrumb + h1 {
  padding-left: rem-calc(15);
  padding-right: rem-calc(15);
  font-family: 'ActaDisplay';
  font-size: rem-calc(33);
  line-height: rem-calc(35);
  font-weight: 900;
  font-style: italic;
  color: $color-dark;

  @include breakpoint(medium) {
    padding-left: rem-calc(0);
    padding-right: rem-calc(0);
    font-size: rem-calc(65);
    line-height: rem-calc(70);
  }
}

.page-page-auteur .breadcrumb + h1{
  max-width: rem-calc(410);
  margin: auto;
  margin-bottom: rem-calc(41);
  text-align: center;

  @include breakpoint(medium) {
    max-width: 70%;
    margin-bottom: rem-calc(104);
    padding-right: 22%;
    text-align: right;
  }

  @include breakpoint(large) {
    max-width: rem-calc(410);
    padding-right: 0;
    text-align: right;
  }
}

.page-node-11 .breadcrumb + h1{
  margin-bottom: rem-calc(80);
}

.breadcrumb + h1 + h2{
  max-width: rem-calc(960);
  margin: auto;
  margin-bottom: rem-calc(45);
  font-family: 'LemonSans';
  font-size: rem-calc(35);
  line-height: rem-calc(36);
  font-weight: 200;
  text-align: center;
}

.content{
  @include flex-grid-row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin-bottom: rem-calc(0);

  @include breakpoint(medium) {
    /*margin-bottom: rem-calc(120);*/
    margin-bottom: 2em;
  }

  .categ-level-2 &{

    @include breakpoint(medium) {
      margin-bottom: 2em;
    }
  }
}
