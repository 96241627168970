@media print {
	@page {
		margin: 2cm 1cm;
	}

	a[href]:after {
		content: none !important;
	}
	.header {
		.header__responsive-items, .header__content__social, .header__content__user {
			display: none;
		}
	}
	.region-navigation,
	.secondary-nav,
	.breadcrumb,
	.messages,
	.right,
	.sticky,
	.navigation,
	.container--footer,
	.region-pre-footer,
	.a-decouvrir,
	.region-bottom,
	.a-lire-aussi {
		display: none;
	}
	.product-shop {
		display: none;
	}

	section[role=main] {
		padding: 0;
		&::before {
			content: none;
		}
	}

	.node-type-articles section[role=main] H1,
	.node-type-dossiers section[role=main] H1 {
		margin-bottom: 0.5cm;
		font-size: 24px;
		line-height: 1.2;
	}

	.container {
		display: block;

		.preview-article__header {
			page-break-inside: avoid;

			figure {
				height: auto;
				overflow: initial;
			}

			img {
				position: relative;
				left: 0;
				top: 0;
				transform: none;
			}
		}

		.node,
		.content {
			display: block;
			width: 100%;
		}

		.visuel {
			page-break-inside: avoid;
			margin-bottom: 1cm;
		}

		.article__type {
			background-color: #ffffff !important;
		}

		.left {
			display: block;
			max-width: 100%;
			width: 100%;
			flex: none;
		}

		.node-dossiers .chapo {
			padding-bottom: 0.5cm;
		}

		.chapo {
			display: inline-block;
			width: 100%;
			page-break-inside: avoid;
			margin-bottom: 0.35cm;
			padding: 0;
			font-size: 18px;
			line-height: 1.25;

			p {
				margin-bottom: 0;
			}
		}

		.body {
			.field-item {
				position: relative;
			}

			h2, h2, h3, h4 {
				position: relative;
				display: inline-block;
				width: 100%;
				page-break-inside: avoid;
				margin-top: 0;
				margin-bottom: 0;
			}

			h2, h3 {
				margin-left: 0;
				font-size: 20px;
			}

			h4 {
				margin-left: 0;
				font-size: 16px;
				line-height: 1.2;
			}

			.block-color {
				display: inline-block;
				width: 100%;
				margin-top: 0;
				margin-bottom: 0.5cm;
				padding: 0.5cm;
				page-break-inside: avoid;
			}

			p + h2,
			p + h3,
			p + h4 {
				margin-top: 0.75cm;
			}


			p {
				position: relative;
				display: inline-block;
				width: 100%;
				margin-left: 0;
				margin-bottom: 0.25cm;
				page-break-inside: avoid;
				break-inside: auto;
				font-size: 14px;
				line-height: 1.2;
			}

			ul {
				margin-left: 0.5cm;
			}

			li {
				font-size: 14px;
				margin-bottom: 0.1cm;
			}

			h2 + p,
			h3 + p,
			h4 + p {
				margin-top: 0.25cm;

				&.block-color {
					margin-top: 0.25cm;
				}
			}

			h2 + h2,
			h2 + h3,
			h3 + h3,
			h3 + h4,
			h4 + h4 {
				margin-top: 0.5cm;
			}

			strong {
				font-size: 14px;
			}
		}
	}

}