/*
* We are hiding the invisible nav outside the screen
* so we need to avoid the horizontal scroll
*/

/* okayNav styles */
body.okayNav-loaded {
  overflow-x: hidden;
}
.okayNav:not(.loaded) {
  visibility: hidden;
  user-select: none;
}
.okayNav ul {
  /* We want two navigations - one hidden and one visible */
  float: left;
}
.okayNav a {
  /* Links general styling */
  position: relative;
  z-index: 1;
}
.okayNav__nav--visible {
  overflow: hidden;
  white-space: nowrap;
}
.okayNav__nav--visible li {
  display: inline-block;
}
.okayNav__nav--visible a {
  /* Link styling for the visible part of the nav */
  display: block;
}

/* Link styling for the off-screen part of the nav */
// .okayNav__nav--invisible {
//   position: fixed;
//   top: 70px;
//   bottom: 0;
//   overflow-y: scroll;
//   -webkit-overflow-scrolling: touch;
//   background: #fff;
// }
// .okayNav__nav--invisible a {
//   background: #fff;
//   width: 240px;
//   display: block;
//   padding: 15px;
// }
// .okayNav__nav--invisible.nav-left {
//   left: 0;
//   -webkit-transform: translateX(-100%);
//           transform: translateX(-100%);
// }
// .okayNav__nav--invisible.nav-right {
//   right: 0;
//   -webkit-transform: translateX(100%);
//           transform: translateX(100%);
// }
// .okayNav__nav--invisible.transition-enabled {
//   transition: -webkit-transform 400ms cubic-bezier(0.55, 0, 0.1, 1);
//   transition: transform 400ms cubic-bezier(0.55, 0, 0.1, 1);
//   transition: transform 400ms cubic-bezier(0.55, 0, 0.1, 1), -webkit-transform 400ms cubic-bezier(0.55, 0, 0.1, 1);
// }
// .okayNav__nav--invisible.nav-open {
//   -webkit-transform: translateX(0);
//           transform: translateX(0);
// }
/* Kebab icon */
.okayNav__menu-toggle {
  //position: relative;
  //transition: transform .3s;
  cursor: pointer;
  //width: 40px;
  //height: 20px;
  //float: left;
  z-index: 1;
  //top: 15px;
  transform: rotate(90deg);
}
.okayNav__menu-toggle.okay-invisible {
  position: absolute;
  opacity: 0;
  user-select: none;
  pointer-events: none;
}
.okayNav__menu-toggle span {
  display: block;
  position: absolute;
  margin: auto;
  height: 4px;
  width: 4px;
  background: #2e2e33;
  border-radius: 50%;
  left: 0;
  right: 0;
  pointer-events: none;
}
.okayNav__menu-toggle span:nth-child(1) {
  top: 25%;
}
.okayNav__menu-toggle span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}
.okayNav__menu-toggle span:nth-child(3) {
  bottom: 25%;
}
.okayNav__menu-toggle.icon--active {
  /* Kebab icon when off-screen nav is open */
  transform: none;
}
.okayNav__menu-toggle.icon--active span {
  //background: #546edb;
}
