/* ==========================================================================
header.scss
========================================================================== */

.header {
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: rem-calc(15 0);
  box-shadow: rem-calc(0 5 20) rgba($color-darker, .2);
  transition: box-shadow .2s;

  @include breakpoint(medium) {
    position: static;
  }

  @include breakpoint(large) {
    padding: rem-calc(35 0);
    box-shadow: none;
  }

  &__logo {
    display: inline-block;
    width: 100%;
    max-width: rem-calc(280);
  }

  &__responsive-items{
    @include hide-for(large);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .account{
      position: absolute;
      top: rem-calc(17);
      right: rem-calc(16);
      padding: 0;
      width: rem-calc(44);
      height: rem-calc(44);
      line-height: rem-calc(44);
      background-color: #f1f1f1;
      border-radius: 100%;
      overflow: hidden;
      transition: color .2s, background-color .2s, transform .2s $easeOutBack;
      color: $color-darker;

      &::after{
        @include glyph;
        @extend .icon-login:before;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: rem-calc(16);
        transform: translate(-50%, -50%);
      }
      // Hover
      &:hover{
        color: $color-main;
        transform: scale(1.2);
      }
      span{
        display: block;
        text-indent: 101%;
      }
    }
  }

  &__content{
    @include flex-grid-row( $size: #{rem-calc(1600)} );
    @include flex-align(center, middle);
    position: relative;
    min-height: rem-calc(50);

    @include breakpoint(medium) {
      @include flex-align(justify, middle);
      min-height: rem-calc(60);
      padding-left: 3vw;
      padding-right: 3vw;
    }

    &__social{
      @include show-for(large);
      position: relative;
      z-index: 10;
      //@include flex-grid-column(4, 0);
    }
    &__logo{
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem-calc(150);
      z-index: 4;
      text-align: center;
      transform: translate(-50%, -50%) scale(1);
      transition: transform .4s $easeOutQuart;

      @include breakpoint(large) {
        width: rem-calc(290);
      }

      .node-type-articles .unpinned &,
      .node-type-dossiers .unpinned & {
        transform: translate(-50%, -100%) scale(.94);
      }
    }
    &__user{
      @include show-for(large);
      position: absolute;
      width: calc(50% - 3vw - #{rem-calc(145)});
      height: rem-calc(70);
      right: 3vw;
      top: 0;
      z-index: 6;

      &__actions{
        @include flex-grid-row();
        .share-current {
          .button {
            white-space: nowrap;
            margin-left: 1rem;
            padding: .7rem 1rem;
          }
        }
      }
    }
    // Dans le cas d'un article (seulement créé dans le sticky header)
    &__article-title{
      @include show-for(large);
      @include flex-grid-row($size: expand);
      @include flex-align(left, middle);
      position: absolute;
      top: 0;
      left: 3vw;
      z-index: 5;
      background-color: #fff;
      width: calc(100% - 6vw);
      padding-left: rem-calc(100);
      padding-right: rem-calc(270);
      font-size: rem-calc(20);
      height: rem-calc(70);
      transition: transform .4s $easeOutQuart;
      transform: translate3d(0, 100%, 0);
      font-size: rem-calc(28);

      .unpinned &{
        transform: none;
      }
    }
  }
}


/**
* House icon
*/
// Mobile only
.navbar.mobile .is-drilldown > ul > li:not(.js-drilldown-back):first-child > a{
  padding-left: rem-calc(46);
  position: relative;

  &::before{
    @include glyph;
    @extend .icon-home:before;
    position: absolute;
    top: 50%;
    left: rem-calc(20);
    transform: translateY(-50%);
  }
}
// Desktop only
.navbar:not(.mobile) > ul > li:not(.js-drilldown-back):first-child > a{
  padding-left: rem-calc(60);
  width: rem-calc(60);
  overflow: hidden;

  &::before{
    @include glyph;
    @extend .icon-home:before;
    position: absolute;
    top: 50%;
    left: rem-calc(20);
    transform: translateY(-50%);
  }
}
/**
* Navbar Large+
*/
.navbar:not(.mobile) {
  @include show-for(large);
  position: relative;
  box-shadow: inset 0 1px 0 0 $color-divider, inset 0 -1px 0 0 $color-divider;
  //border-top: 1px solid $color-divider;
  //border-bottom: 1px solid $color-divider;
  z-index: 4;

  & > ul {
    text-align: center;
    max-width: rem-calc(1600);
    margin-left: auto;
    margin-right: auto;
    padding-right: rem-calc(80);
    position: relative;

    @include breakpoint(xlarge) {
      padding-left: rem-calc(100);
      padding-right: rem-calc(100);
    }
  }

  & > ul > li {
    padding: rem-calc(0 12);
    display: inline-block;
    vertical-align: top;
    line-height: 1;

    @include breakpoint(xlarge) {
      padding-left: rem-calc(15);
      padding-right: rem-calc(15);
    }

    &:first-child{ padding: 0; }

    & > a {
      position: relative;
      display: block;
      padding: rem-calc(15 0);
      font-size: rem-calc(20);

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: transparent;
        display: block;
        width: 100%;
        height: rem-calc(3);
        transform: scaleX(0);
        transform-origin: 100% 50%;
        transition: transform .3s $easeOutQuart, background-color .25s;
      }
    }

    &.is-active,
    &:hover {

      a::after {
        transform: scaleX(1);
        transform-origin: 0 50%;
        background: darken(#CCD4D7, 20%);
      }
    }

    &:hover{

      // Mega Menu Opening
      .navbar__submenu {
        display: flex;
        animation-delay: .15s;
      }
    }

    &.boite-a-outil {
      ul {
        a:hover {
          color: $color-toolbox;
        }
      }
      &.is-active,
      &:hover {
        a::after {
          background-color: $color-toolbox;
        }
      }
    }
    &.bien-manger {
      ul {
        a:hover {
          color: $color-goodfood;
        }
      }
      &.is-active,
      &:hover {
        a::after {
          background-color: $color-goodfood;
        }
      }
    }
    &.cuisines-et-recettes {
      ul {
        a:hover {
          color: $color-recipes;
        }
      }
      &.is-active,
      &:hover {
        a::after {
          background-color: $color-recipes;
        }
      }
    }
    &.se-soigner {
      ul {
        a:hover {
          color: $color-healthcare;
        }
      }
      &.is-active,
      &:hover {
        a::after {
          background-color: $color-healthcare;
        }
      }
    }
    &.bien-etre {
      ul {
        a:hover {
          color: $color-healthy;
        }
      }
      &.is-active,
      &:hover {
        a::after {
          background-color: $color-healthy;
        }
      }
    }
    &.forme {
      ul {
        a:hover {
          color: $color-shape;
        }
      }
      &.is-active,
      &:hover {
        a::after {
          background-color: $color-shape;
        }
      }
    }
    ul {
      a {
        &:hover {
          color: $color-main;
        }
      }
    }
  }

  a {
    font-size: rem-calc(18);
    color: $color-dark;
    text-transform: uppercase;
  }

  // Mega Menu default state (closed)
  .navbar__submenu {
    display: none;
    position: absolute;
    left: 50%;
    top: 100%;
    //margin-top: -1px;
    //border-top: solid 1px #ededed;
    background: darken($color-light, 1%);
    width: 100%;
    max-width: rem-calc(1040);
    transform-origin: 50% 0;
    animation: megaMenu .4s $easeOutExpo both;
    backface-visibility: hidden;

    ul {
      flex: 0 0 25%;
      padding: rem-calc(40 32);
      text-align: left;
    }

    li {
      line-height: 1;
    }

    a {
      display: block;
      transition: color .2s;
      padding: rem-calc(5 0);
    }

    .view-articles {
      flex: 0 0 75%;
      padding: rem-calc(35 30);
      text-align: left;
      background: #ffffff;

      .focus__title {
        display: none;
      }

      .views-field-title {
        margin-bottom: rem-calc(7);

        a{
          font-size: rem-calc(24);
          line-height: rem-calc(24);
          font-weight: normal;
          text-transform: none;
        }
      }

      .views-field-body {
        font-size: rem-calc(18);
        line-height: rem-calc(21);
        font-weight: 300;
        color: $color-content;

        display: none; /* ticket - demande du client */
      }

      .views-field-field-article-categorie{
        a{
          text-transform: none;

          display: none;
        }
      }
    }

    // Articles in Submenu
    // Only displayed on large+
    .view-content {
      @include flex-grid-row;
      @include flex-align(justify, top);

      .preview-article{
        @include flex-grid-column(30.5%, 0);
        padding: 0;
      }
      .preview-article figure{
        height: rem-calc(170);
        margin-bottom: rem-calc(15);
      }
      .preview-article a{
        padding: 0;
      }
      .preview-article .article__type{
        left: 50%;
        transform: translateX(-50%);
      }
      .preview-article::after{
        display: none;
      }
    }

    .views-row {
      flex: 0 0 33%;
    }
  }
}



/**
* Secondary nav
* Contains Menu Level 2 & 3
* Menu Level 3 cannot be rendered in flex due to okayNav plugin limits
* we need classic floats -,-'
*/
.secondary-nav{
  color: #fff;
  background: $color-dark;
  text-transform: uppercase;
  font-family: 'LemonSans';
  font-size: rem-calc(18);
  font-weight: 500;
  padding: 0 1em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  //float: left;

  //display: flex;
  //justify-content: center;

  &__content{
    // flex: 0 0 100%;
    //width: 50%;
    //overflow: hidden;
    // max-width: rem-calc(1400);
    //max-width: 60rem;
    //text-align: center;
    // height: rem-calc(50);

    //text-align: center;
    max-width: rem-calc(1400);
    // padding: 0 rem-calc(100);
    margin: 0 auto;
    position: relative;

    //background: red;
    //overflow: hidden;
    // &::before, &::after {
    //   content:'';
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   z-index: 1;
    //   right: 0;
    // }
    // &::before {
    //   //left: 0;
    //   width: 100%;
    //   transform: translate(100%, 0);
    //   background: $color-dark;
    // }
    // &:after {
    //   width: rem-calc(50);
    //   background: rgba(46,46,48,0);
    //   background: -moz-linear-gradient(left, rgba(46,46,48,0) 0%, rgba(46,46,48,1) 100%);
    //   background: -webkit-gradient(left top, right top, color-stop(0%, rgba(46,46,48,0)), color-stop(100%, rgba(46,46,48,1)));
    //   background: -webkit-linear-gradient(left, rgba(46,46,48,0) 0%, rgba(46,46,48,1) 100%);
    //   background: -o-linear-gradient(left, rgba(46,46,48,0) 0%, rgba(46,46,48,1) 100%);
    //   background: -ms-linear-gradient(left, rgba(46,46,48,0) 0%, rgba(46,46,48,1) 100%);
    //   background: linear-gradient(to right, rgba(46,46,48,0) 0%, rgba(46,46,48,1) 100%);
    //   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2e2e30', endColorstr='#2e2e30', GradientType=1 );
    // }
  }
  .menu-level-2, .menu-level-3 {
    padding-right: 3.5em;
    a {
      display: inline-block;
      vertical-align: middle;
    }
    ul {
      //line-height: inherit;
      &.boite-a-outil {
        li.is-active a, li:hover > a {
          color: lighten($color-toolbox, 0%);
        }
      }
      &.bien-manger {
        li.is-active a, li:hover > a {
          color: lighten($color-goodfood, 0%);
        }
      }
      &.cuisines-et-recettes {
        li.is-active a, li:hover > a {
          color: lighten($color-recipes, 0%);
        }
      }
      &.se-soigner {
        li.is-active a, li:hover > a {
          color: lighten($color-healthcare, 0%);
        }
      }
      &.bien-etre {
        li.is-active a, li:hover > a {
          color: lighten($color-healthy, 0%);
        }
      }
      &.forme {
        li.is-active a, li:hover > a {
          color: lighten($color-shape, 0%);
        }
      }
    }
    // li {
    //   line-height: 3;
    //   transition: line-height .5s;
    // }
    li {
      > a {
        display: block;
      }
    }
  }

  .okayNav__menu-toggle {
    position: absolute;
    z-index: 3;
    top: 50%;
    right: 0;
    transform: translate(0, -50%) !important;
  }

  // Nav container (level 2 & 3)
  .menu-level-2,
  .menu-level-3 {
    @extend .clearfix;
    position: relative;

    // Menu Toggle
    & > a {
      transform: none;
      &:hover,
      &:focus{ background: none; outline: none; }

      span {
        position: static;
        padding-right: rem-calc(20);
        width: auto;
        height: rem-calc(50);
        line-height: rem-calc(50);
        background: transparent;
        color: #ffffff;

        &::before,
        &::after {
          border-right: 2px solid #ffffff;
          content: '';
          display: block;
          height: 8px;
          position: absolute;
          margin-top: -4px;
          transform: rotate(-135deg);
          right: rem-calc(5);
          top: 50%;
          width: 0;
          transition: transform .3s $easeOutQuart;
        }

        &::after {
          right: rem-calc(10);
          transform: rotate(135deg);
        }
      }

      &.icon--active {
        span {
          &::before {
            transform: rotate(-45deg);
          }
          &::after {
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  // Reset lists default styles
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    &:first-child{
      padding-left: rem-calc(15);
      padding-right: rem-calc(10);

      @include breakpoint(large) {
        padding: rem-calc(0 40);
        padding-right: rem-calc(10);
      }
    }
    // okayNav dropdown menu
    &:nth-child(2){
      width: 100%;
      position: absolute;
      top: rem-calc(50);
      right: 0;
      z-index: 10;
      white-space: normal;
      text-align: left;
      display: none;

      @include breakpoint(large) {
        max-width: rem-calc(500);
        column-count: 2;
        column-rule: 1px solid #444;
        padding: rem-calc(12 0);
      }

      &.nav-open{
        display: block;
        transform-origin: 50% 0;
        animation: okayNav .3s $easeOutBack both;
      }

      li{
        display: block;
        width: 100%;
        line-height: 1;
        margin-bottom: rem-calc(20);
      }
      a{
        padding: rem-calc(0 16);
      }


    }
  }

  // List items
  #okaynav1 {
    max-height: em(48);
    overflow: hidden;
    transition: all .45s;
    font-size: em(13);

    ul {
      transition: all .45s;
      line-height: 2;
      padding: .5em 0;
    }
    &.-is-open {
      //padding-top: .5em;
      //padding-bottom: .5em;
      max-height: 40em;
      //line-height: 2;
      ul {
        //line-height: 2;
      }
      > ul > li {

      }
      .arrow {
        &::before{
          transform: rotate(-45deg);
        }
        &::after{
          transform: rotate(45deg);
        }
      }
      @include breakpoint(large) {
        max-height: 14em;
      }
    }
    @include breakpoint(large) {
      font-size: 1em;
    }
  }

  li{
    line-height: inherit;
    display: inline-block;
    vertical-align: middle;
    padding: 0 .5em;
    a {
      display: block;
      line-height: inherit;
      color: #fff;
      transition: color 0.25s;
    }

    &:hover,
    &.is-active {
      a {
        color: $color-main;

        .boite-a-outil & {
          color: $color-toolbox;
        }

        .bien-manger & {
          color: $color-goodfood;
        }

        .cuisines-et-recettes & {
          color: $color-recipes;
        }

        .se-soigner & {
          color: $color-healthcare;
        }

        .bien-etre & {
          color: $color-healthy;
        }

        .forme & {
          color: $color-shape;
        }
      }
    }
    &.menu-parent {
      position: relative;
      opacity: 0.5;
      padding-right: rem-calc(26);

      &::after{
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: rem-calc(18);
        height: rem-calc(14);
        margin: rem-calc(-7 0 0 14);
        background: url(../img/arrow_right_white.svg) no-repeat left top;
        background-size: rem-calc(18) auto;
      }
    }
  }
}



/**
* Menu Level 2
*/
.menu-level-2:not(.sublevel) {

  ul {
    @include flex-grid-row;
    list-style: none;
    margin: auto;
    margin-bottom: 0;
    padding: rem-calc(11 0);

    @include breakpoint(medium) {
      padding: 0;
    }
  }
}

/**
* Menu Level 3
*/
.menu-level-3 {


}



// ### User Menu
.user-menu{
  @include menu-base;
  @include flex-align(right, middle);
  flex: 1 1 auto;

  // Not on mobile
  @include breakpoint(medium) {
    height: rem-calc(70);
    transform: translateY(0);
    transition: transform .4s $easeOutQuart;

    .node-type-articles .unpinned &,
    .node-type-dossiers .unpinned & {
      transform: translateY(-100%);
    }

    & > li:not(:first-child){
      margin-left: rem-calc(16);
    }
  }

  // Override Foundation
  &:not(.mobile) > li:not(.subscribe-cta)> a{
    padding: 0;
    width: rem-calc(40);
    height: rem-calc(40);
    line-height: rem-calc(40);
    background-color: #f1f1f1;
    border-radius: 100%;
    overflow: hidden;
    transition: color .2s, background-color .2s, transform .2s $easeOutBack;
    color: $color-darker;
    border: none;
    cursor: pointer;

    // Icon
    position: relative;
    &::after{
      @include glyph;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: rem-calc(16);
      transform: translate(-50%, -50%);
    }
    &.nl::after{
      @extend .icon-newsletter:before;
    }
    &.account::after{
      @extend .icon-login:before;
    }

    // Hover
    &:hover{
      color: $color-main;
      transform: scale(1.2);
    }
  }

  // Label Inside hidden ONLY on Large+
  &:not(.mobile) span{
    display: block;
    text-indent: 101%;
  }
}

// ### Share current Page / Article
.share-current{
  display: none;
  visibility: hidden;
}
.share-current{
  @include menu-base;
  @include menu-direction(horizontal);
  @include flex-align(right, middle);
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: rem-calc(70);
  background: #fff;
  z-index: 1;
  transition: transform .4s $easeOutQuart;
  transform: translate3d(0, 100%, 0);

  .node-type-articles .sticky &,
  .node-type-dossiers .sticky & {
    display: flex;
    visibility: visible;
  }
  .node-type-articles .unpinned &,
  .node-type-dossiers .unpinned & {
    transform: none;
  }

  li{
    margin-left: rem-calc(20);
  }

  li:not(:first-child) a{
    padding: 0;
    width: rem-calc(40);
    height: rem-calc(40);
    line-height: rem-calc(40);
    background-color: #f1f1f1;
    border-radius: 100%;
    overflow: hidden;
    transition: color .2s, background-color .2s, transform .2s $easeOutBack;
    color: $color-darker;
    border: none;
    cursor: pointer;

    // Icon
    position: relative;
    &::after{
      @include glyph;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    // Hover
    &:hover{
      color: $color-main;
      transform: scale(1.2);
    }

    span{
      @include breakpoint(large) {
        display: block;
        text-indent: 101%;
      }
    }
  }
}

// Icons
ul.share-current li:nth-child(2) a::after{ @extend .icon-facebook:before; }
ul.share-current li:nth-child(3) a::after{ @extend .icon-twitter:before; }
ul.share-current li:nth-child(4) a::after{ @extend .icon-ios-email-outline:before; font-size: rem-calc(26); }
ul.share-current li:nth-child(5) a::after{ @extend .icon-print:before; font-size: rem-calc(20); }

/**
* Search
*/
// ### Search Btn
.search-btn-trigger{
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  line-height: rem-calc(45);

  // Glyph
  &::after{
    @include glyph;
    @extend .icon-search:before;
    position: absolute;
    top: 50%;
    left: rem-calc(20);
    transform: translate(0, -50%);
  }

  span{
    display: block;
    text-indent: 101%;
    cursor: pointer;
  }
}
// Custom Desktop
.navbar:not(.mobile) .search-btn-trigger{
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  line-height: rem-calc(50);
  min-height: rem-calc(50);
  width: rem-calc(50);
  cursor: pointer;

  @include breakpoint(medium) {
    width: rem-calc(100);

    // Glyph
    &::after{
      left: 50%;
      transform: translate(-50%, -50%);
    }

    // Left Border
    &::before{
      content: "";
      width: 1px;
      height: 60%;
      background-color: #ededed;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}




// ### Search Modal
.search-modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background: #fff;
  will-change: opacity, transform;
  display: none;

  .element-invisible{
    @extend .visually-hidden;
  }

  &__content{
    height: 100%;
    overflow-y: auto;
  }

  .block-search,
  .search-form,
  .search-form>div{
    height: 100%;
  }


  & > .block{
    padding: rem-calc(20);
    max-width: rem-calc(1200);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1;

    @include breakpoint(medium) {
      padding-top: 15vh;
    }
  }

  // Interactions
  .search-opened &{
    display: block;
    animation: popIn .6s $easeOutExpo both;
  }

  // Close Button
  .close-button{
    top: rem-calc(20);
    right: rem-calc(20);
    z-index: 1;

    @include breakpoint(medium) {
      top: 6vw;
      right: 6vw;
      font-size: rem-calc(48);
    }
  }

  // Input Text
  .custom-search-box{
    font-size: rem-calc(36);
    line-height: 1.5;
    height: auto;
    border: none;
    padding: 0;
    margin: 15vh 0 7vh;
    border-radius: 0;
    box-shadow: inset rem-calc(0 -2 0 0) #ededed;

    &:focus{
      box-shadow: inset rem-calc(0 -2 0 0) $color-main;
    }

    @include breakpoint(large) {
      font-size: rem-calc(48);
    }
  }

  // Content layout
  .container-inline{
    @include flex-grid-row;
    @include flex-align(center, top);
    height: 100%;

    & > h2,
    & > .form-item,
    & > .form-actions{
      @include flex-grid-column(11, 0);
    }
    & > .form-actions{
      display: none;
    }
    // & > .form-item-custom-search-vocabulary-5{
    //   padding-bottom: 2vh;
    //   margin-bottom: 2vh;
    //   border-bottom: 1px solid #ededed;
    // }
    // Checkboxes container
    .custom-search-vocabulary:not(.form-checkbox){
      @include flex-grid-row;

      // Checkbox
      .form-type-checkbox{
        margin: rem-calc(5 10 5 0);
        backface-visibility: hidden;
        animation: filterIn .6s 100ms $easeOutExpo both;
        animation-play-state: paused;

        .search-opened &{
          animation-play-state: running;

          @for $i from 2 through 50 {
            &:nth-child(#{$i}) { animation-delay: 100 + $i*07ms; }
          }
        }
      }
      label{
        margin: 0;
        font-size: rem-calc(20);
        padding: rem-calc(1 13);
        box-shadow: inset rem-calc(0 0 0 2) #ededed;
        border-radius: rem-calc(20);
        transition: color .1s, box-shadow .2s;
        position: relative;

        &::after,
        &::before{
          position: absolute;
          top: rem-calc(-4);
          right: rem-calc(-8);
          width: rem-calc(22);
          height: rem-calc(22);
          transform: scale(0);
          transition: transform .2s $easeOutBack;
        }
        &::after{
          @include glyph;
          @extend .icon-checkmark:before;
          color: #fff;
          font-size: rem-calc(16);
          top: rem-calc(-1);
          right: rem-calc(-11);
        }
        &::before{
          content: "";
          border-radius: 100%;
          background-color: $color-alt;
          box-shadow: rem-calc(0 0 0 3) #fff;
        }
      }
      [type="checkbox"]{
        @extend .visually-hidden;

        &:checked + label{
          color: $color-alt;
          box-shadow: inset rem-calc(0 0 0 2) rgba($color-alt, .8);
        }
        &:checked + label::before,
        &:checked + label::after{
          transform: scale(1);
        }
      }
    }
  }
}

@keyframes popIn {
  0%{
    opacity: 0;
    transform: scale(0.9);
  }
  100%{
    opacity: 1;
    transform: none;
  }
}
@keyframes popOut {
  0%{
    opacity: 1;
    transform: none;
  }
  100%{
    opacity: 0;
    transform: scale(0.8);
  }
}
@keyframes filterIn {
  0%{
    opacity: 0;
    transform: translateY(50%);
  }
  100%{
    opacity: 1;
    transform: none;
  }
}


#trigger-menu{
  position: absolute;
  top: rem-calc(14);
  left: rem-calc(8);
  z-index: 13;
  margin: 0;
  border: none;
  width: rem-calc(60);
  height: rem-calc(50);
  font-size: 0;
  background: none;
  @extend .hide-for-large;

  @include breakpoint(medium) {
    top: rem-calc(35);
    right: rem-calc(30);
  }

  span{
    @include disable-mouse-outline;
    position: absolute;
    width: rem-calc(22);
    height: rem-calc(2);
    top: 50%;
    left: 50%;
    background: $color-darker;
    transform: translate(-50%, -50%);

    &::before,
    &::after{
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit;
      transition: transform .2s $easeOutBack, background .3s;
    }
    &::before{
      transform: translateY(rem-calc(-6));
    }
    &::after{
      transform: translateY(rem-calc(6));
    }

    // Menu opened
    .nav-open &{
      background: none;

      &::before{
        background: $color-darker;
        transform: rotate(45deg);
      }
      &::after{
        background: $color-darker;
        transform: rotate(-45deg);
      }
    }
  }
}

/**
* Offcanvas mobile
* .navbar.mobile cloned from .navbar
* see `src/global.js`
*/
.navbar.mobile{
  position: fixed;
  height: 100vh;
  top: 0;
  width: 85vw;
  padding-top: rem-calc(80);
  max-width: rem-calc(340);
  z-index: 9; // Header is 10
  transition: transform .35s $easeOutExpo;
  transform: translate3d(-100%, 0, 0);
  background: #fff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .nav-open &{
    transform: none;
  }

  .is-drilldown ul{
    @include menu-base;
    @include menu-direction(vertical);
    align-items: flex-start;
    align-content: flex-start;

    // Foundation padding override
    &>li>a{
      padding: rem-calc(14 20 12);
    }
  }

  .is-drilldown li:not(:last-child){
    border-bottom: 1px solid $color-divider;
  }
  .is-drilldown .js-drilldown-back a{
    position: relative;
    background-color: $color-divider;
    font-weight: 600;
    font-size: rem-calc(16);
    letter-spacing: rem-calc(1);
    color: $color-main;
    padding: rem-calc(18 20 15 50);
  }
  a{
    font-size: rem-calc(18);
    color: $color-dark;
    text-transform: uppercase;
  }


  // User Menu mobile
  .user-menu{
    @include menu-base;
    @include menu-direction(vertical);
    padding: rem-calc(25 30);
    border-top: 1px solid $color-divider;

    li{
      border-bottom: none;
      margin: rem-calc(5 0);
      order: 1;

      &.subscribe-cta{
        order: 0;
        margin-bottom: rem-calc(15);
      }
      &.subscribe-cta a{
        color: #fff;
      }
    }
    li:not(.subscribe-cta) a{
      position: relative;
      border-bottom: none;

      &::before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background-color: #f1f1f1;
        width: rem-calc(40);
        height: rem-calc(40);
        border-radius: 100%;
      }
      // Icon
      &::after{
        @include glyph;
        position: absolute;
        top: 50%;
        left: rem-calc(12);
        font-size: rem-calc(16);
        transform: translate(0, -50%);
      }
      &.nl::after{
        @extend .icon-newsletter:before;
      }
      &.account::after{
        @extend .icon-login:before;
      }
    }

    &>li:not(.subscribe-cta)>a{
      padding-left: rem-calc(55);
    }
  }

  //Social buttons
  .social-icons {
    padding: rem-calc(25 0 80);
    justify-content: center;
    border-top: 1px solid $color-divider;
  }
}
// Backdrop overlay
.overlay{
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $color-darker;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity .2s, visibility 0s;

  .nav-open &{
    opacity: .7;
    visibility: visible;
    pointer-events: auto;
  }
}
/**
* Drilldown Menu
*/
// Better Drilldown transition
.is-drilldown-submenu{
  will-change: transform;
  transition: transform .5s $easeOutExpo;
  background-color: #fff;

  &.is-active {
    transform: translateX(-100%);
  }

  &.is-closing {
    transform: translateX(0%);
    transition-duration: .4s;
  }

  li a{
    display: block;
  }
}
// Right Triangle
.is-drilldown-submenu-parent>a::after{
  @include glyph;
  @extend .icon-arrow-line-right:before;
  border: none;
  top: rem-calc(19);
  right: rem-calc(30);
}
// Left Triangle
.js-drilldown-back>a::before{
  @include glyph;
  @extend .icon-ios-arrow-thin-left:before;
  border: none;
  position: absolute;
  top: rem-calc(9);
  left: rem-calc(15);
  font-size: rem-calc(34);
  color: $color-dark;
}


/**
* Sticky Header
* container with header + navbar inside
* uses Headroom.js to show/hide
* @url http://wicky.nillia.ms/headroom.js/
*/
.sticky{
  @include show-for(large);
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  transition: transform .3s $easeOutQuart, box-shadow .2s;
  transform: translateY(-100%);

  .header__content{
    min-height: rem-calc(70);
    background: #fff;
    overflow: hidden;
  }
  .header__logo{
    width: rem-calc(180);
  }
  .social-icons{
    display: none;
  }

  .secondary-nav {
    //display: none;
    position: static;
  }

  // Sticky Navbar hidden by default
  .navbar{
    //display: none;
    // position: absolute;
    // top: 100%;
    // left: 0;
    // width: 100%;
    background: #fff;
    .navbar__submenu {
      display: none !important;
    }
  }

  #toggled__container {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    .secondary-nav__content {
      &::before, &::after {
        content: none;
      }
    }
    .menu-level-3 > ul {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      text-align: left;
      white-space: normal;
      padding: 1em 0 1em percentage(1/4);
      > li {
        flex: 0 0 percentage(1/3);
        line-height: 1;
        margin-bottom: .5em;
        > a {
          line-height: 1;
        }
        &.menu-parent {
          position: absolute;
          top: 1em;
          left: 0;
        }
      }
    }
    li {
      visibility: visible;
    }
    @media print, screen and (min-width: 90em) {
      .menu-level-3 > ul {
        padding: 1em 0 1em percentage(1/5);
        > li {
          flex: 0 0 percentage(1/4);
        }
      }
      .menu-level-2 > ul {
        padding: 1em 0 1em 0;
      }
    }
  }

  .trigger-nav{
    background: #fff;
    color: $color-dark;
    font-family: 'LemonSans', sans-serif;
    font-weight: 600;
    font-size: rem-calc(18);
    transition: color .2s;
    position: relative;
    padding-left: rem-calc(28);

    &:hover{
      color: $color-main;
      background: #fff;
      box-shadow: none;

      &::before,
      &::after{
        background-color: $color-main;
      }
    }
    &:focus{
      background: #fff;
      box-shadow: none;
    }

    &::before,
    &::after{
      content: "";
      position: absolute;
      left: 0;
      top: rem-calc(19);
      width: rem-calc(12);
      height: rem-calc(2);
      background-color: $color-dark;
      transition: transform .25s $easeOutQuart, background-color .2s;
    }
    &::before{
      left: 0;
      transform: rotate(45deg);
    }
    &::after{
      left: rem-calc(8);
      transform: rotate(-45deg);
    }
  }

  &.sticky-opened .trigger-nav::before{
    transform: rotate(-45deg);
  }
  &.sticky-opened .trigger-nav::after{
    transform: rotate(45deg);
  }

  // ### Hide & Show animations
  &.top {

  }
  &.not-top {
    transform: none;
    box-shadow: rem-calc(0 5 20) rgba($color-darker, .2);
  }
}


@keyframes megaMenu {
  0%{
    opacity: 0;
    transform: translateX(-50%) scaleY(.7);
    box-shadow: rem-calc(0 0 0 0) $shadow-hard;
  }
  100%{
    opacity: 1;
    transform: translateX(-50%) scaleY(1);
    box-shadow: rem-calc(0 30 50 0) $shadow-hard;
  }
}
@keyframes okayNav {
  0%{
    opacity: 0;
    transform: scaleY(.7);
  }
  100%{
    opacity: 1;
    transform: scaleY(1);
  }
}
@keyframes subMenuItem {
  0%{
    opacity: 0.01;
    transform: translateY(20%);
  }
  100%{
    opacity: 1;
    transform: none;
  }
}
