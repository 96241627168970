/* ==========================================================================
  mixins.scss
========================================================================== */

@mixin glyph {
  font-family: "nutricons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// Hover animation only on mouse devices (not touch)
@mixin hover_animation {
  [data-whatintent="mouse"] &{
    backface-visibility: hidden;
    will-change: transform;
    transition: transform .45s $easeOutQuart;

    &:hover{
      transform: scale(.97);
    }
  }
}
/**
 * Page standard standard styles
 * used in front and in CK Editor
 */
@mixin standard_styles {

  // headings
  h1, .h1 {
    font-family: 'ActaDisplay', Georgia, serif;
    font-size: rem-calc(40);
    font-weight: normal;
    line-height: rem-calc(35);

    @include breakpoint(medium) {
      font-size: rem-calc(80);
      line-height: rem-calc(62);
    }
  }

  h2, .h2 {
    font-family: 'ActaDisplay', Georgia, serif;
    font-size: rem-calc(27);
    line-height: rem-calc(20);
    font-weight: 700;
    font-style: italic;
    margin: rem-calc(40 0 30);
    clear: both;

    @include breakpoint(medium) {
      font-size: rem-calc(32);
      line-height: rem-calc(26);
    }
  }

  h3, .h3 {
    margin-bottom: rem-calc(15);
    font-size: rem-calc(23);

    @include breakpoint(medium) {
      font-size: rem-calc(27);
      margin-left: rem-calc(50);
    }
  }

  .h3 {
    margin-left: 0;
  }

  h4, .h4 {
    margin-bottom: rem-calc(15);
    font-size: rem-calc(22);
    color: $color-subscriber;
    font-weight: 500;

    @include breakpoint(medium) {
      font-size: rem-calc(24);
      margin-left: rem-calc(50);
      font-weight: 600;
    }
  }

  .h4 {
    margin-left: 0;
  }

  // Paragraphs
  p{
    font-size: rem-calc(20);
    font-weight: 300;
    line-height: 1.45;
    margin-bottom: rem-calc(30);

    @include breakpoint(medium) {
      margin-left: rem-calc(50);
    }
  }
  p.excerpt{
    font-weight: 400;
    font-size: rem-calc(27);
    line-height: rem-calc(23);
    line-height: 1.1;
    border-bottom: 1px solid $color-divider;
    padding-bottom: rem-calc(30);

    @include breakpoint(medium) {
      font-size: rem-calc(35);
    }
  }

  // Quote
  p.citation,
  blockquote.citation{
    background: #f1f1f1;
    margin-bottom: rem-calc(30);
    padding: rem-calc(60 30 40);
    margin-left: 0;
    font-family: 'ActaDisplay', serif;
    font-weight: 600;
    font-size: rem-calc(19);
    line-height: 1.4;
    font-style: italic;
    overflow: hidden;
    position: relative;

    @include breakpoint(medium) {
      font-size: rem-calc(24);
      line-height: 1.3;
      padding: rem-calc(60 50 40);
    }

    &::before{
      @include glyph;
      @extend .icon-lquote:before;
      position: absolute;
      top: rem-calc(-10);
      left: rem-calc(15);
      color: $color-main;
      font-size: rem-calc(52);
    }
  }

  .block-color {
    display: table;
    margin-top: rem-calc(30);
    margin-bottom: rem-calc(30);
    padding: rem-calc(30 40);
    font-size: 19px;
    line-height: 1.3 !important;
    font-weight: 300 !important;
    border: rem-calc(2) solid #999999;
    background-color: #f5f5f5;

    @include breakpoint(medium) {
      margin-left: rem-calc(50);
    }

    H1,
    H2,
    H3, .h3,
    H4, .h4{
      margin-left: 0;
      line-height: 1.2 !important;
      font-weight: 500 !important;

      &:first-child{
        margin-top: rem-calc(11);
      }
    }

    b,
    strong{
      font-weight: 500 !important;
    }

    p{
      margin-left: 0;
    }

    .title{
      font-size: 22px;
      font-weight: 500 !important;
    }

    @at-root {
      .boite-a-outil & {
        border-color: $color-toolbox;
        background-color: lighten($color-toolbox, 23%);
      }

      .bien-manger & {
        border-color: darken($color-goodfood, 6%);
        background-color: lighten($color-goodfood, 23%);
      }

      .cuisines-et-recettes & {
        border-color: $color-recipes;
        background-color: lighten($color-recipes, 23%);
      }

      .se-soigner & {
        border-color: $color-healthcare;
        background-color: lighten($color-healthcare, 30%);
      }

      .bien-etre & {
        border-color: $color-healthy;
        background-color: lighten($color-healthy, 23%);
      }

      .forme & {
        border-color: $color-shape;
        background-color: lighten($color-shape, 30%);
      }
    }
  }

  // Lists
  ul{
    margin-left: rem-calc(20);
    margin-bottom: rem-calc(30);
    line-height: 1.45;

    @include breakpoint(medium) {
      margin-left: rem-calc(90);
    }

    li{
      margin-bottom: rem-calc(10);
      font-size: rem-calc(20);
      font-weight: 300;
    }
  }
}


$browser-context: 16; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@function lh($size, $lh: ($size*1.2)) {
  @return #{($lh/$size)};
}
