/**
* Process de paiement
*/


.paiement-process{
  @include flex-grid-row;
  @include flex-align(center, top);

  &__content{
    @include flex-grid-column(11, 0);

    @include breakpoint(medium) {
      @include flex-grid-column(8, 0);
      margin-top: rem-calc(-50);
      padding-top: rem-calc(30);
      background: #fff;
    }
  }

  &__title{
    text-align: center;

    img{
      display: inline-block;
    }
  }

  &__body{
    margin: rem-calc(30 0 0);
    text-align: center;

    p{
      font-family: 'LemonSans', sans-serif;
      font-size: rem-calc(20);
      font-weight: 300;

      strong{
        font-weight: 500;
        text-decoration: underline;
      }
    }

    [type="submit"]{
      padding: rem-calc(14 28 14 50);
      margin-top: rem-calc(30);
      position: relative;
      font-size: rem-calc(18);

      @include breakpoint(medium) {
        margin-bottom: rem-calc(40);
      }

      &::before{
        @include glyph;
        @extend .icon-lock:before;
        position: absolute;
        top: rem-calc(13);
        left: rem-calc(18);
      }
    }
  }
}
