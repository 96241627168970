/* ==========================================================================
  categorie.scss
=========================================================================== */

.categ-level-1,
.categ-level-2{

  h2.categorie-chapo{
    margin-bottom: rem-calc(44);
    font-size: rem-calc(22);
    line-height: rem-calc(29);
    font-weight: 200;
    text-align: center;
    padding: 0 1em;
    @include breakpoint(medium) {
      font-size: rem-calc(35);
      line-height: rem-calc(36);
    }

    p{
      line-height: inherit;
    }
  }
}

.categ-level-3{
  @include flex-grid-row;
  margin-top: rem-calc(53);

  .categorie-visuel{
    margin: rem-calc(0 0 30);

    @include breakpoint(medium) {
      margin: rem-calc(0 0 50);
    }

    img{}
  }

  .block-subtitle{
    @include flex-grid-column(12);
    margin-bottom: rem-calc(0);
    padding-bottom: rem-calc(11);
    border-bottom: rem-calc(1) solid $color-divider;

    @include breakpoint(medium) {
      margin-bottom: rem-calc(36);
    }

    h2.categorie-chapo{
      font-size: rem-calc(22);
      line-height: rem-calc(29);
      font-weight: 300;

      @include breakpoint(medium) {
        font-size: rem-calc(30);
        line-height: rem-calc(36);
      }

      p{
        line-height: inherit;
      }
    }
  }

  .articles-categorie-and-others{
    .view-content{
      @include flex-grid-row;
      justify-content: space-between;
      margin-bottom: rem-calc(48);
    }

    .mini{
      @include flex-grid-column(12, 0);

      @include breakpoint(medium) {
        @include flex-grid-size(46%);
      }
    }
  }
}

h2.categorie-chapo{
  a{
    transition: color 0.25s $easeOutQuad;

    @at-root {
      .boite-a-outil & {
        color: darken($color-toolbox, 20%);
      }

      .bien-manger & {
        color: darken($color-goodfood, 20%);
      }

      .cuisines-et-recettes & {
        color: darken($color-recipes, 20%);
      }

      .se-soigner & {
        color: darken($color-healthcare, 20%);
      }

      .bien-etre & {
        color: darken($color-healthy, 20%);
      }

      .forme & {
        color: darken($color-shape, 20%);
      }
    }

    &:hover{
      color: darken($color-dark, 6%);
    }
  }
}
