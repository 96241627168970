/* ==========================================================================
  breadcrumb.scss
========================================================================== */

.breadcrumb {
  @include flex-grid-row;
  position: relative;
  z-index: 2;
  margin: auto;
  margin-bottom: 1em;
  //padding: rem-calc(25 15 0 15);
  @include breakpoint(small only) {
    display: none;
  }
  @include breakpoint(medium) {
    margin-bottom: 2em;
    //padding: rem-calc(25 0 0 0);
  }

  .front &{
    display: none;
  }

  .node-type-aliments &,
  .page-user-subscribe-proceed &{
    margin-bottom: rem-calc(26);
  }

  &__title,
  &__separator {
    display: none;
  }


  &__list {
    @include flex-grid-row;
    width: 100%;
    list-style: none;

    li {
      &::after {
        position: relative;
        content:'/';
        margin: rem-calc(0 10 0 10);
        color: $color-content;
      }

      &:last-child::after{
        display: none;
      }

      a {
        color: $color-content;
        transition: color 0.25s;

        &:hover{
          color: $color-dark;
        }
      }
    }
  }
}
