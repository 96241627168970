/* ==========================================================================
  pub.scss
========================================================================== */

.pub {
  position: relative;
  margin-bottom: 3.25rem;

  @include breakpoint(medium) {
    //margin-bottom: rem-calc(101);
  }

  &:not(.right &) {
    margin-bottom: rem-calc(44);
  }

  .view-publicites {
    position: relative;
    margin: auto;
    text-align: center;
  }
}
