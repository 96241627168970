/* ==========================================================================
  newsletter.scss
========================================================================== */

.newsletter {
  padding: rem-calc(30);
  box-shadow: rem-calc(3 3 30 1) $shadow-soft;
  background: #FFFFFF;

  .featured--newsletter & {
    width: calc(100% - 30px);
    margin: 0 auto;
    padding: rem-calc(17 7);
    box-shadow: rem-calc(0 0 0 13) lighten($color-main, 20%);
    border: rem-calc(13) solid transparent;
    border-image: url(../img/bg_pattern_stripes.png) 13 round;
    border-image-outset: 1;
  }

  &__subscribe {

    &:first-child:not(:only-child) {
      border-bottom: rem-calc(1) solid $color-divider;
    }

    &__title {
      font-family: 'ActaDisplay';
      font-size: rem-calc(25);
      font-weight: 900;
      font-style: italic;
    }
  }

  &__form {
    margin-bottom: rem-calc(22);

    form {
      .form-wrapper {
        display: flex;
      }
    }

    .form-item {
      label {
        display: none;
      }

      .form-text {
        height: rem-calc(40);
        padding-left: rem-calc(14);
        border-right: none;
        border-radius: 0;
      }

      &-checkbox {
        display: flex;
        margin-top: rem-calc(10);
        align-items: baseline;

        .form-checkbox {
          margin-bottom: 0;
          margin-right: rem-calc(5);
        }

        .description {
          line-height: initial;
        }
      }
    }

    .form-submit {
      height: rem-calc(40);
      padding: rem-calc(0 14);
      font-family: 'LemonSans';
      font-size: rem-calc(16);
      font-weight: 600;
      color: #FFFFFF;
      letter-spacing: rem-calc(1);
      text-transform: uppercase;
      background: $color-main;
      border: none;
      border-radius: 0;
    }
  }

  /* CAPTCHA */

  .g-recaptcha {
    margin-top: rem-calc(15);
    transform: scale(0.78);
    transform-origin: 0 0;

    @include breakpoint(medium) {
      transform: scale(0.88);
    }

    @include breakpoint(large) {
      transform: scale(0.67);
    }

    @include breakpoint(rem-calc(1160)) {
      transform: scale(0.80);
    }

    @include breakpoint(rem-calc(1215)) {
      transform: scale(0.88);
    }
  }

  &__guide {
    display: block;
    padding-top: rem-calc(20);

    @include breakpoint(rem-calc(399) down) {
      text-align: center;
    }

    @include breakpoint(rem-calc(400)) {
      display: flex;
    }

    img {
      border: rem-calc(1) solid darken($color-divider, 3%);
      margin-right: rem-calc(25);

      @include breakpoint(rem-calc(399) down) {
        margin-right: 0;
      }
    }

    &__title {
      font-size: rem-calc(30);
      line-height: 1;
      font-weight: 900;
      color: $color-main;
      text-transform: uppercase;
    }

    &__content {
      margin-top: rem-calc(10);

      @include breakpoint(rem-calc(400)) {
        margin-top: 0;
      }
    }
  }

  &__content {
    margin-bottom: rem-calc(13);
    font-size: rem-calc(20);
    line-height: rem-calc(20);

    p {
      line-height: 1;
    }
  }

  .footer &{
    @include flex-grid-column(12);
    margin-bottom: rem-calc(30);

    @include breakpoint(large) {
      @include flex-grid-size(3);
    }
  }
}

.footer {

  .newsletter {
    /* CAPTCHA */

    .g-recaptcha {
      margin-top: rem-calc(15);
      transform: scale(0.78);
      transform-origin: 0 0;

      @include breakpoint(medium) {
        transform: scale(0.88);
      }

      @include breakpoint(large) {
        transform: scale(0.61);
      }

      @include breakpoint(rem-calc(1098)) {
        transform: scale(0.67);
      }

      @include breakpoint(rem-calc(1215)) {
        transform: scale(0.79);
      }
    }
  }
}
