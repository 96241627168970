/* ==========================================================================
  featured.scss
========================================================================== */

// Block with featured articles
.featured {
  padding-bottom: rem-calc(40);

  @include breakpoint(medium) {
    padding-left: rem-calc(15);
    padding-right: rem-calc(15);
    padding-bottom: rem-calc(75);
  }

  @include breakpoint(xlarge) {
    padding-left: rem-calc(0);
    padding-right: rem-calc(0);
  }

  .container &{

    @include breakpoint(medium) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 4em;
    }

    @include breakpoint(xlarge) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  h2 {
    position: relative;
    display: block;
    max-width: rem-calc(1024);
    margin: 0 auto;
    margin-bottom: rem-calc(30);
    font-family: 'ActaDisplay', Georgia, serif;
    font-size: rem-calc(27);
    line-height: rem-calc(27);
    font-weight: 900;
    font-style: italic;
    text-align: center;

    @include breakpoint(medium) {
      font-size: rem-calc(35);
      line-height: rem-calc(35);
    }

    @at-root .special-abonne#{&} {

      @include breakpoint(small only) {
        color: #FFFFFF;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      max-width: rem-calc(780);
      height: rem-calc(8);
      top: 50%;
      right: 50%;
      transform: translateY(-50%) translateX(50%);
      background-image: url('../img/bg_pattern_stripes.png');
      background-color: #ccd4d7;
      background-position: left center;
      background-repeat: repeat;
      z-index: 1;

      @at-root .boite-a-outil#{&} {
        background-color: rgba($color-toolbox, .5);
      }

      @at-root .bien-manger#{&} {
        background-color: rgba($color-goodfood, .5);
      }

      @at-root .cuisines-et-recettes#{&} {
        background-color: rgba($color-recipes, .5);
      }

      @at-root .se-soigner#{&} {
        background-color: rgba($color-healthcare, .5);
      }

      @at-root .bien-etre#{&} {
        background-color: rgba($color-healthy, .5);
      }

      @at-root .forme#{&} {
        background-color: rgba($color-shape, .5);
      }

      @at-root .special-abonne#{&} {
        background-color: #FFFFFF;

        @include breakpoint(small only) {
          display: none;
        }
      }

      @include breakpoint(small only) {
        display: none;
      }
    }

    span {
      padding: rem-calc(0 15);
      background: #ffffff;
      position: relative;
      display: inline-block;
      z-index: 2;

      @at-root .special-abonne#{&}{

        @include breakpoint(small only) {
          background: transparent;
        }
      }
    }
  }

  h3 {
    position: relative;
    display: block;
    max-width: rem-calc(1024);
    margin: 0 auto;
    margin-bottom: rem-calc(10);
    padding-left: 5%;
    padding-right: 5%;
    font-family: 'LemonSans', Arial, sans-serif;
    font-weight: 200;
    font-size: rem-calc(24);
    line-height: rem-calc(24);
    text-align: center;
    line-height: rem-calc(30);

    @include breakpoint(medium) {
      margin-bottom: rem-calc(30);
      padding: 0;
      font-size: rem-calc(28);
      line-height: rem-calc(28);
    }
  }

  &.special-abonne{
    position: relative;
    margin-bottom: rem-calc(35);
    padding-bottom: rem-calc(57);
    text-align: center;

    @include breakpoint(small only) {
      padding-top: rem-calc(55);
    }

    @include breakpoint(medium){
      margin-bottom: 4em;
    }

    &::before{
      position: absolute;
      display: block;
      content: '';
      right: 50%;
      bottom: 0;
      width: 100vw;
      height: 80%;
      height: calc( 100% - 10em );
      background: #4e4f63 url(../img/bg_pattern_circles.png) repeat left top;
      transform: translateX(50%);

      @include breakpoint(xxlarge) {
        width: rem-calc(1600);
      }

      @include breakpoint(small only) {
        height: 100%;
        width: 100%;
      }
    }

    h3{
      color: #FFFFFF;

      @include breakpoint(medium) {
        color: $color-content;
      }
    }

    .row{
      text-align: left;
    }

    .button{
      position: relative;
      margin: auto;
      text-align: center;
    }
  }

  &.featured--six{
    border-bottom: rem-calc(1) solid $color-divider;
    padding-bottom: 0;
    margin-bottom: rem-calc(41);

    @include breakpoint(medium) {
      margin-bottom: 4em;
    }
  }

  &--newsletter{
    margin-bottom: rem-calc(35);

    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  &.last-articles{
    padding-bottom: rem-calc(0);

    @include breakpoint(medium) {
      //padding-bottom: rem-calc(75);
    }
  }
}
