/* ==========================================================================
  bottom.scss
========================================================================== */

.region-bottom {
  @include flex-grid-row;
  padding: rem-calc(26 15 22 15);
  font-size: rem-calc(15);
  line-height: rem-calc(20);
  font-weight: 300;

  @include breakpoint(medium) {
    padding-bottom: rem-calc(12);
    font-size: rem-calc(18);
    line-height: rem-calc(22);
  }

  @include breakpoint(xlarge) {
    padding-left: rem-calc(0);
    padding-right: rem-calc(0);
  }

  #block-block-7{
    @include flex-grid-size(12);
    @include flex-grid-row;
    justify-content: center;

    @include breakpoint(medium) {
      justify-content: space-between;
    }

    ul{
      @include flex-grid-row;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: rem-calc(11);

      @include breakpoint(medium) {
        justify-content: flex-start;
        margin: rem-calc(0);
      }

      li{
        list-style: none;
        margin: rem-calc(0 20 0 0);

        a{
          display: block;
          overflow: hidden;
          position: relative;
          padding: rem-calc(0 0 0 0);
          color: $color-dark;

          &::before{
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            width: 100%;
            height: 0;
            border: rem-calc(1) solid $color-main;
            transform: translateX(-101%);
            transition: transform 0.25s $easeOutQuad, color 0.60s;
          }

          &:hover{
            color: $color-main;
            &::before{
              transform: translateX(0);
            }
          }
        }
      }
    }

    a#choosit{
      opacity: 0.5;
      transition: opacity 0.25s;

      &:hover{
        opacity: 1;
      }
    }
  }
}
