/* ==========================================================================
  global.scss
========================================================================== */

html {
  font-size: 100%;
}

body {
  font: normal 16px 'LemonSans', Arial, sans-serif;
  font-size: 100%;
  line-height: rem-calc(26);

  // Lock scrolling when search/nav are opened (Doesnt work on iOS)
  &.search-opened,
  &.nav-open{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  // Fixed header
  @include breakpoint(small only) {
    padding-top: rem-calc(80);
  }
}

a {
  color: $color-main;
  -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
  backface-visibility: hidden;

  &:focus {
    outline: none;
    color: $color-dark;
  }

  &:hover{
    color: $color-dark;
  }
}

strong {
  font-weight: 700;
}

#skip-link {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'LemonSans', Arial, sans-serif;
  margin-top: 0;
}

h1 {
  font-size: rem-calc(40);
  font-weight: normal;
  line-height: rem-calc(35);

  @include breakpoint(medium) {
    font-size: rem-calc(80);
    line-height: rem-calc(62);
  }
}

h2 {
  font-size: rem-calc(26);
  font-weight: 800;
  line-height: rem-calc(30);
}

h3 {
  /*font-size: rem-calc(20);
  font-weight: 600;
  line-height: rem-calc(26);*/
  margin: rem-calc(27 0 27 0);
  font-size: rem-calc(25);
  font-weight: 500;
  line-height: rem-calc(29);
}

strong {
  font-weight: 500;
}

a, input[type="submit"], input[type="button"] {

}

.highlight {
  color: $color-main;
}

/* Helpers */
/*
* Hide only visually, but have it available for screenreaders: h5bp.com/v
*/
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  /*
  * Extends the .visuallyhidden class to allow the element to be focusable
  * when navigated to via the keyboard: h5bp.com/p
  */
  &--focusable {
    &:active,
    &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }
}

.element-invisible {
  @include element-invisible;
}

/* box sizing */
*, *::after, *::before {
  box-sizing: border-box;
}

.row {
  &.expanded {
    max-width: $max-width-grid;
  }
}


section[role="main"]{
  overflow: hidden;
}

.content {
  align-items: flex-start;
}

.right {
  transition: transform .4s .05s; // smooth sticky sidebar
}

.region-bottom {
  #choosit {
    display: none;
  }
}