/* ==========================================================================
  fonts.scss
========================================================================== */
@charset "UTF-8";

@font-face {
	font-family: 'ActaDisplay';
	src: url('../fonts/hinted-ActaDisplayW01-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'ActaDisplay';
	src: url('../fonts/hinted-ActaDisplayW01-BoldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'ActaDisplay';
	src: url('../fonts/hinted-ActaDisplayW01-XBold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'ActaDisplay';
	src: url('../fonts/hinted-ActaDisplayW01-XBoldItalic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'ActaDisplay';
	src: url('../fonts/hinted-ActaDisplayW01-BlackItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'LemonSans';
	src: url('../fonts/hinted-LemonSansCnd-Thin.woff2') format('woff2'),
		url('../fonts/hinted-LemonSansCnd-Thin.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'LemonSans';
	src: url('../fonts/hinted-LemonSansRnd-SLight.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'LemonSans';
	src: url('../fonts/hinted-LemonSansRndCnd-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'LemonSans';
	src: url('../fonts/hinted-LemonSansRndCnd-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'LemonSans';
	src: url('../fonts/hinted-LemonSansRndCnd-Bold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}


@font-face {
  font-family: "nutricons";
  src:url("../fonts/nutricons.eot");
  src:url("../fonts/nutricons.eot?#iefix") format("embedded-opentype"),
    url("../fonts/nutricons.woff") format("woff"),
    url("../fonts/nutricons.ttf") format("truetype"),
    url("../fonts/nutricons.svg#nutricons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon-arrow-line-bottom:before {
  content: "\61";
}
.icon-arrow-line-left:before {
  content: "\62";
}
.icon-arrow-line-right:before {
  content: "\63";
}
.icon-arrow-line-top:before {
  content: "\64";
}
.icon-facebook:before {
  content: "\65";
}
.icon-home:before {
  content: "\66";
}
.icon-login:before {
  content: "\67";
}
.icon-newsletter:before {
  content: "\68";
}
.icon-search:before {
  content: "\69";
}
.icon-twitter:before {
  content: "\6a";
}
.icon-checkmark:before {
  content: "\6b";
}
.icon-quote:before {
  content: "\6c";
}
.icon-lquote:before {
  content: "\6d";
}
.icon-googleplus:before {
  content: "\6e";
}
.icon-instagram:before {
  content: "\6f";
}
.icon-arrow-thickline-bottom:before {
  content: "\70";
}
.icon-arrow-thickline-left:before {
  content: "\71";
}
.icon-arrow-thickline-right:before {
  content: "\72";
}
.icon-arrow-thickline-top:before {
  content: "\73";
}
.icon-checkmark-round:before {
  content: "\74";
}
.icon-choosit-logo:before {
  content: "\75";
}
.icon-ios-arrow-thin-left:before {
  content: "\76";
}
.icon-lock:before {
  content: "\77";
}
.icon-lock-1:before {
  content: "\78";
}
.icon-ios-email-outline:before {
  content: "\79";
}
.icon-print:before {
  content: "\7a";
}
.icon-venus:before {
  content: "\41";
}
.icon-mars:before {
  content: "\42";
}
.icon-arrow-line-bottom-1:before {
  content: "\43";
}
.icon-home-1:before {
  content: "\44";
}
.icon-login-1:before {
  content: "\45";
}
.icon-arrow-line-right-1:before {
  content: "\46";
}
.icon-arrow-line-left-1:before {
  content: "\47";
}
.icon-lquote-1:before {
  content: "\48";
}
.icon-arrow-long-right:before {
  content: "\49";
}
.icon-newsletter-1:before {
  content: "\4a";
}
.icon-quote-1:before {
  content: "\4b";
}
.icon-rss:before {
  content: "\4c";
}
.icon-search-1:before {
  content: "\4d";
}
.icon-twitter-1:before {
  content: "\4e";
}
.icon-arrow-line-top-1:before {
  content: "\4f";
}
.icon-facebook-1:before {
  content: "\50";
}
.icon-pinterest:before {
  content: "\51";
}