/* ==========================================================================
  auteur.scss
=========================================================================== */

.page-auteur{
  @include flex-grid-row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  .left {
    margin: 0;
  }
  .pub{
    flex: 1 100%;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .view-content{
    margin-bottom: rem-calc(37);
  }
}

.detail-auteur{
  margin-bottom: rem-calc(77);
  padding: rem-calc(55 30);
  text-align: center;
  background: #FFFFFF;
  box-shadow: rem-calc(0 11 50 0) $shadow-medium;

  .auteur-visu{
    display: block;
    width: rem-calc(160);
    height: rem-calc(160);
    margin: auto;
    margin-bottom: rem-calc(25);
    border-radius: 50%;
  }

  .name-auteur{
    display: block;
    font-weight: 600;
    font-size: rem-calc(28);
    line-height: rem-calc(32);
    text-transform: uppercase;
  }

  .profession-auteur{
    display: block;
    margin-bottom: rem-calc(26);
    font-size: rem-calc(26);
    line-height: rem-calc(30);
    font-weight: 300;
    color: $color-content;
  }

  .description-auteur{
    margin: rem-calc(0 0 14 0);
    font-size: rem-calc(18);
    line-height: rem-calc(23);
    font-weight: 300;
    color: $color-content;
  }

  .block-social-icons{
    @include flex-grid-row;
    justify-content: center;

    a{
      @include glyph;
      position: relative;
      display: block;
      width: rem-calc(32);
      height: rem-calc(32);
      margin: rem-calc(0 5);
      text-indent: -11000px;
      color: #FFFFFF;
      border-radius: 50%;
      transition: box-shadow 0.15s $easeOutQuad;

      &:hover{
        box-shadow: rem-calc(0 0 0 3) $color-dark;
      }

      &::before{
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        content: '';
        width: rem-calc(20);
        height: rem-calc(20);
        text-indent: 0;
        font-size: rem-calc(17);
        line-height: rem-calc(19);
        transform: translate(-50%, -50%);
      }

      &.auteur-facebook{
        background-color: #3e5b98;

        &::before{
          @extend .icon-facebook:before;
        }

        &:hover{
          box-shadow: rem-calc(0 0 0 3) darken(#3e5b98, 5%);
        }
      }

      &.auteur-twitter{
        background-color: #4da7de;

        &::before{
          @extend .icon-twitter:before;
        }

        &:hover{
          box-shadow: rem-calc(0 0 0 3) darken(#4da7de, 5%);
        }
      }

      &.auteur-instagram{
        background-color: #405de6;

        &::before{
          @extend .icon-instagram:before;
          transform: translate(-48%, -50%);
        }

        &:hover{
          box-shadow: rem-calc(0 0 0 3) darken(#405de6, 4%);
        }
      }

      &.auteur-google{
        background-color: #dd4b39;

        &::before{
          @extend .icon-googleplus:before;
          font-size: rem-calc(20);
          transform: translate(-45%, -50%);
        }

        &:hover{
          box-shadow: rem-calc(0 0 0 3) darken(#dd4b39, 5%);
        }
      }
      
      &.auteur-siteweb{
        background-color: #df5858;

        &::before{
          background:url(../img/website.svg) no-repeat 0 0;
        }

        &:hover{
          box-shadow: rem-calc(0 0 0 3) darken(#df5858, 5%);
        }
      }
    }
  }
}

.page-page-auteur {

  .right{
    & + .pub{
      order: 3;

      @include breakpoint(medium) {
        order: initial;
      }
    }
  }
}
