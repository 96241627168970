/* ==========================================================================
    admin.scss - affichage connecté pour un admin/superadmin
   ========================================================================== */

.logged-in {

  // Tabs (voir / modifier / devel)
  .tabs {
    @include tabs-container;
    @include flex-grid-row;
    margin-bottom: 5vh;
    box-shadow: rem-calc(0 15 30) rgba($color-dark, .15);
    position: relative;
    z-index: 1;

    @include breakpoint(medium) {
      margin-top: rem-calc(85);
    }

    &__tab {
      @include tabs-title;
      border-right: 1px solid $color-divider;
    }

    a {
      font-size: rem-calc(18);
      color: $color-main;
      transition: color .2s, background-color .2s;

      &.is-active {
        color: #fff;
        background-color: $color-main;
      }

      &:hover {
        color: $color-main;
        background-color: #f0f0f0;
      }
    }
  }
}

.node-type-page-standard .tabs {
  //margin-top: rem-calc(200);
}

// Content
.page-user {

  // Hauteur mini du contenu
  section[role="main"] {
    min-height: rem-calc(700);
  }

  .element-invisible{
    display: none;
  }

  .page-title{
    margin-bottom: rem-calc(0);

    @include breakpoint(medium) {
      margin-bottom: rem-calc(55);
    }

    h1{
      font-size: rem-calc(50);
      line-height: rem-calc(55);
    }
  }

  .region-pre-footer{
    display: none;
  }

  .footer{
    padding-top: rem-calc(55);
  }

  // Menu
  .menu {
    @include flex-grid-row(12);
    align-items: flex-start;
    margin-bottom: rem-calc(20);
    text-transform: uppercase;
    background-color: #FFFFFF;
    border-bottom: 1px solid $color-divider;
    box-shadow: rem-calc(0 0 42 0) $shadow-soft;

    @include breakpoint(medium) {
      margin-bottom: rem-calc(40);
    }

    &__item {
      font-size: rem-calc(20);

      &:last-child {
        text-align: right;
        flex: 1;
      }
    }

    a {
      display: inline-block;
      font-size: rem-calc(16);
      color: $color-dark;
      transition: color .2s;
      border-right: rem-calc(1) solid $color-divider;
      transition: background-color 0.35s $easeOutExpo, color 0.35s;

      &:hover {
        color: #FFFFFF;
        background-color: $color-main;
      }

      @include breakpoint(medium) {
        font-size: rem-calc(18);
      }
    }

    & .menu{
      display: none;
    }
  }

  // Récap
  .menu + .field-type-list-text {
    @include callout-base;
    @include flex-grid-row($size : rem-calc(1024));
    font-size: rem-calc(18);

    .field-label {
      padding-right: rem-calc(30);
    }
  }

  // Bouton
  .change-profile {
    @include flex-grid-row($size : rem-calc(1024));
    @include flex-align(center, middle);

    @include breakpoint(medium) {
      min-height: rem-calc(200);
    }

    a {
      @extend .button;
    }
  }

  // Login
  #user-login{
    @include flex-grid-row();
    @include flex-align(center, top);
    @include flex-grid-column(12, 0);
    width: 100%;

    @include breakpoint(medium) {
      margin-top: rem-calc(60);
    }

    & > div{
      @include flex-grid-row;
      width: 100%;
      /*@include flex-grid-column(8, 0);
      padding: rem-calc(50 60);
      background-color: #fff;
      box-shadow: rem-calc(0 15 60) rgba($color-dark, .2);*/
    }
  }

  .create-account{
    @include flex-grid-row;
    justify-content: space-between;
    align-items: center;
    padding: rem-calc(25);
    background: $color-light url(../img/bg_pattern_paper.jpg) repeat left top;
    box-shadow: rem-calc(0 15 60) rgba($color-dark, .2);
    border: rem-calc(2) solid $color-divider;

    .login-register{
      @extend .button;
    }

    @include breakpoint(medium) {
      padding: rem-calc(50 60);
    }
  }

  #user_login_form {
    margin-bottom: rem-calc(43);
    padding: rem-calc(25);
    background-color: #fff;
    box-shadow: rem-calc(0 10 30) rgba($color-dark, .1);

    @include breakpoint(medium) {
      padding: rem-calc(50 60);
    }

    h2{
      margin-bottom: rem-calc(22);
    }

    .form-item {
      margin-bottom: rem-calc(20)
    }

    label {
      margin-bottom: rem-calc(9);
      font-size: rem-calc(19);
      line-height: rem-calc(19);
      color: rgba($color-dark, .9);
    }
    .description {
      color: rgba($color-dark, .5);
    }
    .form-actions {
      @include flex-grid-row;
      @include flex-align(center, top);
      margin-top: rem-calc(40);
      flex: 1 100%;
    }
    .form-submit{
      @extend .button;
    }

    .login-password{
      display: block;
      text-align: right;
      /*margin-top: rem-calc(-44);*/
    }
  }
}



/* PROFIL */

.page-user {

  .container{
    @include flex-grid-row;
    justify-content: center;

    /*@include breakpoint(small only) {
      padding-left: 5%;
      padding-right: 5%;
    }*/

    & > *{
      flex: 1 100%;
    }
  }

  .field {
    @include flex-grid-row;
    @include flex-grid-column(10);
    /*flex: 1 100%;*/
    flex-wrap: wrap;
    margin: rem-calc(0 0 20 0);
    padding: rem-calc(0 0 18 0);
    font-size: rem-calc(17);
    line-height: rem-calc(22);
    font-weight: 300;
    text-align: left;
    border-bottom: rem-calc(1) solid $color-divider;

    .field-label{
      margin: rem-calc(0 12 0 0);
      font-weight: 500;
      font-size: rem-calc(19);
      line-height: rem-calc(20);
    }

    .field-items {
    }

    &.field-name-field-user-description,
    &.field-name-field-user-image,
    &.field-name-field-user-adresse,
    &.field-name-field-user-complement-adresse,
    &.field-name-field-user-cp,
    &.field-name-field-user-ville,
    &.field-name-field-user-tel,
    &.field-name-field-user-portable,
    &.field-name-field-user-fax,
    &.field-name-field-user-profession,
    &.field-name-field-user-societe {
      display: none;
    }
  }

  h3{
    @include flex-grid-column(10);
    flex: 1 100%;
    flex-wrap: wrap;

    & + dl{
      @include flex-grid-column(10);
      justify-content: flex-start;
      margin-bottom: rem-calc(22);
      padding-bottom: rem-calc(55);
      border-bottom: rem-calc(1) solid $color-divider;

      dt,
      dd{
        display: inline-block;
        flex: 0 auto;
        margin: rem-calc(0 12 0 0);
        font-weight: 500;
        font-size: rem-calc(19);
        line-height: rem-calc(20);
      }

      dd{
        text-align: left;
        font-size: rem-calc(17);
        line-height: rem-calc(19);
        font-weight: 300;
        color: $color-main;
      }
    }
  }
}

/* MON COMPTE */

.page-user-subscribe{
  font-size: rem-calc(19);
  line-height: rem-calc(22);
  font-weight: normal;
  color: $color-content;
}

/* ABONNEMENT */

.content-subscription {
  @include flex-grid-row;
  @include flex-grid-column(12,0);
  justify-content: space-between;

  .title{
    font-size: rem-calc(25);
  }

  .left{

    & > .title{
      display: block;
      margin-bottom: rem-calc(55);
    }
  }

  .right{
    margin-bottom: rem-calc(30);
  }
}

.block-info-subscription{
  @include flex-grid-column(12);
  align-items: center;
  margin-bottom: rem-calc(50);
  padding-top: rem-calc(25);
  padding-bottom: rem-calc(20);
  font-size: rem-calc(19);
  line-height: rem-calc(22);
  background: $color-light;
  border-radius: rem-calc(5);
  border: rem-calc(1) solid rgba($color-main, 0.5);

  .line{
    @include flex-grid-column(12, 0);
    @include flex-grid-row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: rem-calc(22);
    padding-bottom: rem-calc(22);
    text-align: center;
    border-bottom: rem-calc(1) dashed darken($color-divider, 10%);

    @include breakpoint(medium) {
      flex-wrap: nowrap;
      justify-content: flex-start;
      text-align: left;
    }

    &:last-child{
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
    }

    &.justify{
      justify-content: center;

      @include breakpoint(medium) {
        justify-content: space-between;
      }
    }

    &.v-center{
      align-items: center;

      .title{
        width: auto;
      }
    }

    p{
      flex: 1 100%;
      line-height: rem-calc(22);

      @include breakpoint(small) {
        flex: 0 auto;
      }
    }
  }

  .title{
    display: block;
    width: 100%;
    margin-right: rem-calc(6);
    font-size: rem-calc(26);
    line-height: rem-calc(27);
    color: $color-main;

    &.alone{
      margin-bottom: rem-calc(15);
      text-align: center;

      @include breakpoint(medium) {
        text-align: left;
      }
    }
  }

  .date{
    font-size: rem-calc(23);
    line-height: rem-calc(25);
    font-weight: 600;
  }

  .button {
    margin: auto;
    margin-top: rem-calc(11);

    @include breakpoint(medium) {
      flex: 0 0 auto;
      margin: 0;
      margin-left: rem-calc(22);
    }
  }

  #code-promo-form {
    margin-bottom: rem-calc(16);

    & > div {
      @include flex-grid-row;
      flex-wrap: nowrap;
      align-items: center;
    }

    label {
      display: none;
    }

    [type="submit"] {
      margin: rem-calc(0 0 0 7);
    }
  }
}

.previous_subscriptions {
  flex: 1 100%;

  @include breakpoint(medium) {
    margin-bottom: rem-calc(66);
  }

  table.views-table {
    border-bottom: rem-calc(1) solid $color-divider;

    th {
      a {
        color: darken($color-content, 5%);
      }
    }
  }
}

#infos-subscribe{
  margin-bottom: rem-calc(50);
}

#user-pass-reset{
  text-align: center;

  h2{
    margin-bottom: rem-calc(22);
    font-weight: 200;
  }
}

/* ---- bloc 7 bonnes raisons ---- */

.raisons_abonnement {
  @include flex-grid-column(11);
  width: calc(100% - 30px);
  margin: 0 auto;
  margin-top: rem-calc(13);
  margin-bottom: rem-calc(55);
  padding: rem-calc(17 7 10 10);
  box-shadow: rem-calc(0 0 0 13) lighten($color-main, 20%);
  border: rem-calc(13) solid transparent;
  border-image: url(../img/bg_pattern_stripes.png) 13 round;
  border-image-outset: 1;
  box-shadow: rem-calc(3 3 30 1) $shadow-soft;
  background: #FFFFFF;

  @include breakpoint(medium) {
    @include flex-grid-column(28%, 0);
    margin-right: rem-calc(13);
    padding: rem-calc(17 10 15 3);
  }

  h2 {
    position: relative;
    margin: auto;
    margin-bottom: rem-calc(30);
    padding: rem-calc(0 0 15 0);
    font-family: 'ActaDisplay';
    font-size: rem-calc(25);
    line-height: rem-calc(27);
    font-weight: 900;
    font-style: italic;
    color: $color-dark;
    text-align: center;

    &::before {
      position: absolute;
      left: 50%;
      bottom: 0;
      content: '';
      width: rem-calc(40);
      height: 0;
      border-bottom: rem-calc(1) solid $color-dark;
      transform: translateX(-50%);
    }
  }

  ol {
    counter-reset: section;
    list-style-type: none;
    font-size: rem-calc(18);
    line-height: rem-calc(21);
    font-weight: 300;

    li {
      position: relative;
      margin: rem-calc(0 0 30 0);
      padding: rem-calc(0 0 0 15);

      strong {
        font-size: rem-calc(20);
        line-height: rem-calc(20);
        font-weight: 500;
      }

      & > strong {
        display: block;
        margin: rem-calc(0 0 -11 0);
      }

      &::before {
        flex: 0 0 rem-calc(65);
        display: inline-block;
        position: absolute;
        left: rem-calc(-33);
        top: 0;
        counter-increment: section;
        content: counters(section,".") " ";
        width: rem-calc(35);
        height: rem-calc(35);
        font-family: 'ActaDisplay';
        font-size: rem-calc(35);
        line-height: rem-calc(37);
        font-weight: 500;
        text-align: right;
        color: $color-dark;
        font-style: italic;
      }

      &:last-child {
        margin-bottom: rem-calc(0);
      }
    }
  }
}

/* BLOC AVIS ABONNES */

.avis_abonnes {
  @include flex-grid-column(12, 0);
  flex: 1 100%;
  margin-bottom: rem-calc(62);
  padding: rem-calc(30);
  font-size: rem-calc(15);
  line-height: rem-calc(17);
  font-weight: 300;
  border: rem-calc(5) solid $color-divider;

  ul {
    @include flex-grid-row;
    list-style: none;

    li {
      flex: 1 100%;
      margin: rem-calc(0 0 21 0);
      padding: rem-calc(0 0 25 0);
      border-bottom: rem-calc(1) solid $color-divider;

      &:last-child{
        border-bottom: none;
        margin: 0;
      }

      @include breakpoint(medium) {
        flex: 1 30%;
        margin: rem-calc(15 0);
        padding: rem-calc(15 30);
        border-right: rem-calc(1) solid $color-divider;
        border-bottom: none;

        &:nth-child(3),
        &:last-child {
          border-right: none;
        }
      }

      strong{
        font-size: rem-calc(20);
      }
    }
  }
}

/* SUBSCRIBE FORM */
.webform-client-form-11 {
  .webform-component--offre {
    p {
      font-size: rem-calc(30);
      line-height: 1.2;
      text-align: center;
      font-weight: 700;
    }
  }
}

#choosit-subsribe-form,
#user-profile-form,
#user-register-form,
.webform-client-form-11 {

  .element-invisible{
    display: none;
  }

  .form-item {
    @include flex-grid-row;
    justify-content: space-between;
    width: 100%;
    margin: rem-calc(0 0 15 0);

    label {
      @include flex-grid-column(12);
      font-size: rem-calc(20);
      font-weight: 500;

      @include breakpoint(medium) {
        @include flex-grid-column(4);
      }
    }

    [type="text"],
    [type="password"],
    [type="email"],
    select,
    .form-pays{
      @include flex-grid-column(12);
      margin: 0;

      @include breakpoint(medium) {
        @include flex-grid-column(8);
      }
    }

    .form-pays{
      padding-top: rem-calc(10);
    }

    .form-textarea-wrapper,
    .image-widget {
      @include flex-grid-column(12, 0);
      margin: 0;

      @include breakpoint(medium) {
        @include flex-grid-column(8, 0);
      }
    }

    select {
      margin-bottom: 0;
      border: rem-calc(1) solid $color-divider;
    }

    &.form-type-checkbox,
    &.form-type-radios,
    &.form-type-radio,
    &.webform-component-radios,
    &.webform-component-boolean{
      @include flex-grid-column(shrink);
      justify-content: flex-start;
      width: auto;
      flex-wrap: nowrap;

      [type="checkbox"],
      [type="radio"] {
        flex: 0 0 auto;
        margin-top: rem-calc(10);
      }

      label {
        padding: 0 !important;

        @include breakpoint(medium) {
          @include flex-grid-column(shrink);
          max-width: 100% !important;
        }
      }

      .form-radios{
        @include flex-grid-row;
        @include flex-grid-column(expand);
        justify-content: flex-start;
      }
    }

    &.form-type-radios.form-item-paiement-method {
      flex-wrap: wrap;

      @include breakpoint(medium) {
        flex-wrap: nowrap;
      }
    }

    &.form-type-radios.form-item-paiement-method {
      flex-wrap: wrap;

      @include breakpoint(medium) {
        flex-wrap: nowrap;
      }
    }

    &.webform-component-boolean,
    &.webform-component-checkboxes,
    &.form-type-checkbox{
      label{
        order: 2;
        margin-right: rem-calc(11);
        margin-left: 0;
        font-weight: 300;
      }

      [type="checkbox"]{
        order: 1;
        margin-right: rem-calc(11);
      }
    }

    &.form-type-radio{
      padding: 0;
      margin-right: rem-calc(11);

      label{
        font-weight: 300;
      }
    }
  }

  .description{
    flex: 1 100%;
    margin: rem-calc(9 0 17 0);
    margin-left: rem-calc(30);
    font-size: rem-calc(13);
    line-height: rem-calc(16);

    a {
      color: #0a0a0a;
      font-size: rem-calc(14);
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    @include breakpoint(small only) {
      margin-left: rem-calc(10);
    }
  }

  [type="submit"] {
    float: right;
    margin-top: rem-calc(11);
  }

  .image-widget-data{
    [type="submit"] {

      @include breakpoint(medium) {
        margin-top: rem-calc(0);
      }
    }
  }

  #edit-field-rgpd {
    .form-type-checkbox {
      position: relative;
      padding-bottom: rem-calc(60);

      .description {
        position: absolute;
        bottom: 0;
        left: 0;
        margin-left: rem-calc(30);

        a {
          color: #0a0a0a;
          font-size: rem-calc(14);
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }

        @include breakpoint(small only) {
          margin-left: rem-calc(10);
        }
      }
    }
  }
}

#user-profile-form{
  [type="submit"] {
    margin-top: rem-calc(-39);
  }
}


/* MODIFY INFOS ACCOUNT */

#user-profile-form,
#user-register-form{
  width: 100%;
  max-width: rem-calc(800);
  margin: auto;
  line-height: rem-calc(19);
  font-weight: 300;

  .form-actions{
    margin-bottom: rem-calc(55);

    [type="submit"] {
      /*margin-top: rem-calc(-40);*/
      margin-bottom: rem-calc(66);
    }

    &#edit-actions--2{
      position: relative;
      z-index: 3;
      display: flex;
      justify-content: flex-end;

      & .form-submit#edit-cancel{
        margin-left: rem-calc(11);
        color: lighten($color-content, 15%);
        background-color: $color-light;
      }
    }
  }

  .unsubsribe-newsletter{
    margin: rem-calc(50 0 11 0);
    padding-left: 33.33333%;

    a{
      position: relative;
      color: $color-content;
      line-height: rem-calc(17);

      &::before{
        position: relative;
        @extend .icon-arrow-line-right:before;
        margin: rem-calc(0 7 0 0);
        font-family: 'nutricons';
        font-size: rem-calc(11);
        color: $color-dark;
        transition: margin 0.25s $easeOutExpo;
      }

      &:hover{
        color: $color-main;

        &::before{
          margin-right: rem-calc(17);
          color: $color-main;
        }
      }
    }
  }
}

#user-register-form{
  @include breakpoint(small only) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}


#edit-account{
  margin: rem-calc(0 0 52 0);
  padding: rem-calc(45 44 18);
  background-color: $color-light;
  border: rem-calc(2) solid $color-divider;
  border-radius: rem-calc(5);

  div.password-strength,
  div.password-confirm/*,
  .description*/{
    flex: 1 100%;
    margin: rem-calc(0 0 17 0);
    margin-left: 33.333%;

    @include breakpoint(small only) {
      margin-left: 0;
    }

    &.password-suggestions{
      padding: rem-calc(22);

      ul{
        margin-top: rem-calc(11);
      }

      & + .description{
        margin-top: 0;
      }
    }
  }

  /*.description{
    margin-top: rem-calc(9);
    font-size: rem-calc(13);
    line-height: rem-calc(16);
  }*/

  .form-item{
    label{
      padding-left: 0;
      font-size: rem-calc(18);
      line-height: rem-calc(20);
      text-transform: uppercase;
      align-self: center;
      text-align: right;
    }
  }
}

.field-group-tab-wrapper{
  margin-top: rem-calc(50);
  margin-bottom: rem-calc(30);

  .vertical-tabs{
    border-color: $color-divider;

    @include breakpoint(small only) {
      margin-left: 0;
    }

    .vertical-tabs-list{
      border-color: $color-divider;

      @include breakpoint(small only) {
        width: 100%;
        margin-left: 0;
        margin-bottom: rem-calc(5);
      }

      .vertical-tab-button{
        border-color: darken($color-divider, 5%);
        text-transform: uppercase;
        font-weight: 300;

        a{
          color: $color-content;
          text-decoration: none;
          transition: background-color 0.35s $easeOutExpo;

          &:hover{
            text-decoration: none;
            outline: none;
            background-color: #FFFFFF;

            strong{
              text-decoration: none;
            }
          }

          strong{
            font-weight: 500;
          }
        }

        &.selected{
          a{
            strong{
              color: $color-dark;
            }
          }
        }
      }
    }

    .vertical-tabs-panes{
      padding-top: rem-calc(22);
    }
  }
}

/* PAIMENT ANNULE */

#payment-form-standalone{
  margin-bottom: rem-calc(55);

  .form-actions{

    [type="submit"]{
      float: right;
    }
  }

  label{
    font-size: rem-calc(18);
    line-height: rem-calc(20);
  }
}

/* FORM FORGOT PASSWORD */

#user-pass{
  max-width: rem-calc(505);
  text-align: center;

  .form-type-textfield{
    margin-bottom: rem-calc(25);
  }

  #google_recaptcha_user_pass{
    margin-bottom: rem-calc(25);

    & > div{
      margin: auto;
    }
  }

  label{
    margin-bottom: rem-calc(12);
    font-size: rem-calc(22);
    line-height: rem-calc(27);
  }

  [type="text"]{
    margin-bottom: rem-calc(11);
  }
}

/* PAGE CONFIRMATION */

.webform-confirmation{
  font-size: rem-calc(22);
  line-height: rem-calc(27);
  text-align: center;
}

.page-node-done{
  .links{
    margin-bottom: rem-calc(110);
    text-align: center;
    a{
      @extend .button;
    }
  }
}

/* PAGE INSCRIPTION NEWSLETTER */

.node-type-webform{
  .articles-conseilles{
    display: none;
  }
}

#block-text-thanks{

  @include breakpoint(medium) {
    @include flex-grid-column(6, 15);
    align-content: center;
  }
}
