/* ==========================================================================
  references.scss
========================================================================== */

.accordion-ref {
  $block-parent: &;
  margin-bottom: rem-calc(30);
  background: none;
  counter-reset: stylish-counter;

  &__title {
    display: flex;
    align-items: center;
    padding-bottom: rem-calc(10);
    font-family: 'ActaDisplay', Georgia, serif;
    font-size: rem-calc(20);
    font-style: italic;
    font-weight: 900;
    color: $color-dark;
    background: none;
    border: none;

    #{$block-parent} :last-child:not(.is-active) > & {
      border-bottom: none;
    }

    &:hover,
    &:focus {
      background: none;
    }

    &::before {
      content: '';

      #{$block-parent}__container.is-active & {
        content: '';
      }
    }
  }

  &__toggler {
    position: relative;
    margin-left: rem-calc(20);
    width: rem-calc(33);
    height: rem-calc(33);
    border-radius: 50%;
    background-color: $color-divider;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: rem-calc(15);
      left: rem-calc(10);
      width: rem-calc(12);
      height: 2px;
      background-color: $color-main;
      transition: opacity .32s $easeOutQuad;
    }

    &::after {
      top: rem-calc(15);
      transform: rotate(90deg);

      #{$block-parent}__container.is-active & {
        opacity: 0;
      }
    }
  }

  &__content {
    margin-bottom: 0;
    margin-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    border: none;

    #{$block-parent} &:last-child {
      border-bottom: none;
    }
  }

  ol > li {
    position: relative;
    padding-top: rem-calc(5);
    padding-bottom: rem-calc(20);
    padding-left: rem-calc(20);
    list-style-type: none;
    font-size: rem-calc(18);
    font-style: italic;

    &::before {
      content: counter(stylish-counter) '.';
      counter-increment: stylish-counter;
      position: absolute;
      top: rem-calc(6);
      left: 0;
      margin-right: rem-calc(2);
      font-family: 'ActaDisplay', Georgia, serif;
      font-weight: 900;
      color: $color-main;
    }
  }

  &__item {
    display: block;
    font-family: LemonSans, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.25;
    color: $color-dark;
  }
}
