/* ==========================================================================
  aliments.scss
=========================================================================== */

/* ----- RECHERCHE ALIMENT ---- */

h2,
.title {
  .page-les-aliments-a-la-loupe &,
  .recherche-aliment &{
    position: relative;
    @include flex-grid-column(12, 0);
    max-width: 100%;
    margin: auto;
    margin-bottom: rem-calc(16);
    font-family: 'ActaDisplay';
    font-size: rem-calc(24);
    line-height: rem-calc(24);
    text-align: center;
    font-weight: 900;
    font-style: italic;

    @include breakpoint(medium) {
      font-size: rem-calc(40);
      line-height: rem-calc(40);
    }
  }

  .page-les-aliments-a-la-loupe &{
    margin-bottom: rem-calc(30);
    text-align: left;
  }

  .page-les-aliments-a-la-loupe .recherche-aliment &{
    margin-bottom: rem-calc(16);
  }

}

.page-les-aliments-a-la-loupe {

  section[role=main] h1{
    margin-bottom: rem-calc(85);
  }

  article {
    @include flex-grid-row;
    justify-content: space-between;
  }

  .left{
    & > div:last-child{
      margin-bottom: rem-calc(0);

      @include breakpoint(medium) {
        margin-bottom: rem-calc(96);
      }
    }
  }

  .subtitle {
    position: relative;
    display: block;
    margin-bottom: rem-calc(7);
    font-size: rem-calc(20);
    line-height: rem-calc(25);
    text-transform: uppercase;
  }
}

.recherche-aliment {
  @include flex-grid-row($size : #{rem-calc(1600)});
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: rem-calc(44);
  padding: rem-calc(39 22 25 22);
  background: url(../img/bg_aliments_loupe.jpg) no-repeat center top;

  @include breakpoint(medium) {
    margin-bottom: 4em;
  }

  .section-les-aliments-a-la-loupe & {
    margin-bottom: rem-calc(25);
    justify-content: flex-start;
    background: url(../img/bg_pattern_recherche_aliment.jpg) no-repeat left top;
  }

  &__content {
    width: 100%;
    max-width: rem-calc(470);
    margin: auto;
    text-align: left;
  }

  form#nutrition-search-aliment-by-name-form {
    @include flex-grid-column(12, 0);

    @include breakpoint(medium) {
      @include flex-grid-column(7, 0);
    }

    & > div {
      @include flex-grid-row;
      justify-content: center;
      margin-bottom: rem-calc(12);
    }

    .form-type-textfield {
      flex: 1;
    }

    .form-text {
      position: relative;
      z-index: 1;
      flex-wrap: nowrap;
      width: 100%;
      height: rem-calc(50);
      font-size: rem-calc(20);
      line-height: rem-calc(20);
      box-shadow: inset 0 0 0 rem-calc(2) $color-divider;

      &:focus {
        border: none;
        box-shadow: inset rem-calc(0 0 0 2) darken($color-divider, 11%);
      }
    }

    .form-submit {
      position: relative;
      z-index: 0;
      flex: 0 0;
      min-width: rem-calc(65);
      color: #ffffff;
      padding: rem-calc(12 22);
      margin: rem-calc(0 0 0 -2);
      border-radius: rem-calc(0);
      font-size: rem-calc(16);
      font-weight: 600;
      color: transparent;
      text-transform: uppercase;
      letter-spacing: rem-calc(1);
      text-indent: -1000%;
      background: #FFFFFF;
      border: none;
      transition: box-shadow .40s $easeOutQuad, background-color .40s $easeOutQuad;
      cursor: pointer;
      box-shadow: inset rem-calc(0 0 0 2) $color-divider;
      background: #FFFFFF url(../img/icon-search.svg) no-repeat 48% center;
      background-size: rem-calc(24 24);

      .section-les-aliments-a-la-loupe & {
        background-size: rem-calc(20 20);
      }

      &:hover {
        background-color: darken($color-divider, 11%);
        box-shadow: inset rem-calc(0 0 0 2) darken($color-divider, 11%);
      }
    }

    .link {
      @include flex-grid-column(12, 0);
      justify-content: flex-start;
      flex: 1 100%;
      margin-top: rem-calc(6);
      font-size: rem-calc(16);
      letter-spacing: rem-calc(1.5);
      text-transform: uppercase;

      a {
        @include flex-grid-row;
        align-items: center;
        position: relative;
        color: $color-dark;

        &::after {
          position: relative;
          content: '';
          display: block;
          margin: rem-calc(0 0 0 8);
          width: rem-calc(21);
          height: rem-calc(14);
          background: transparent url(../img/arrow_right_dark.svg);
          background-size: rem-calc(21 14);
          transition: margin 0.25s $easeOutQuad;
        }

        &:hover {

          &::after {
            margin-left: rem-calc(16);
          }
        }
      }
    }
  }
}

.groupe-aliment {
  position: relative;
  margin: rem-calc(0 0 30 0);
  padding: rem-calc(15);
  background: url(../img/bg_pattern_paper.jpg) repeat left top;

  @include breakpoint(medium) {
    padding: rem-calc(60);
  }

  H2 {
    margin-bottom: rem-calc(43);
  }

  &::before {
    display: block;
    position: absolute;
    z-index: 0;
    right: 0;
    top:0;
    content:'';
    width: rem-calc(473);
    height: rem-calc(199);
    background: url(../img/bg_pattern_groupe_aliment_tronque.png) no-repeat right rem-calc(-17);
    background-size: rem-calc(300) auto;

    @include breakpoint(medium) {
      background-size: auto auto;
      background-position: right top;
    }
  }
}

.group-content {
  position: relative;
  @include flex-grid-row;
  background: #FFFFFF;
  box-shadow: rem-calc(0 15 30 -3) $shadow-medium;
}

#groupes,
#nutriments_by_groupe,
#nutriments_by_alpha {
  padding-top: rem-calc(12);
  padding-bottom: rem-calc(12);
  @include flex-grid-column(6, 0);
  border-right: rem-calc(1) solid $color-divider;

  ul {
    margin: 0;
    list-style: none;
  }

  li {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: rem-calc(17);
    line-height: rem-calc(20);

    @include breakpoint(medium) {
      font-size: rem-calc(20);
      line-height: rem-calc(22);
    }

    &.no-results{
      padding: rem-calc(8 25);
    }

    a {
      position: relative;
      display: block;
      overflow: hidden;
      padding: rem-calc(3 15);
      color: $color-dark;
      transition: background-color 0.25s $easeOutQuad;

      @include breakpoint(medium) {
        padding: rem-calc(8 25);
      }

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: rem-calc(6);
        height: 100%;
        background-color: $color-main;
        transform: translateX(-101%);
        transition: transform 0.25s $easeOutExpo;
      }

      &::after {
        position: absolute;
        right: rem-calc(100);
        top: 50%;
        @include glyph;
        @extend .icon-arrow-line-right:before;
        font-size: rem-calc(14);
        color: transparent;
        width: rem-calc(8);
        height: rem-calc(13);
        transform: translate(0, -50%);
        transition: right 0.5s $easeOutExpo;
      }

      &:hover {
        color: $color-main;
        background-color: $color-light;

        &::before {
          transform: translateX(0);
        }

        &::after {
          transform: translateX(0);
        }
      }

      &.active{
        color: $color-main !important;

        &::after{
          right: rem-calc(11);
          color: $color-main;
          transform: translate(0, -50%);
        }
      }
    }
  }
}

#nutriments_by_groupe{
  overflow: scroll;
  max-height: auto;

  @include breakpoint(medium) {
    max-height: rem-calc(1200);
  }

  @include breakpoint(xlarge) {
    max-height: rem-calc(944);
  }
}

#nutriments_by_alpha{
  overflow: scroll;
  max-height: auto;

  @include breakpoint(medium) {
    max-height: rem-calc(400);
  }

  @include breakpoint(xlarge) {
    max-height: rem-calc(300);
  }
}

#lettres{
  @include flex-grid-column(6, 0);
  padding: rem-calc(17 20);
  border-right: rem-calc(1) solid $color-divider;

  @include breakpoint(medium) {
    padding: rem-calc(25 30);
  }

  ul {
    @include flex-grid-row;
    justify-content: center;
    margin: rem-calc(0 0);
    list-style: none;

    @include breakpoint(medium) {
      margin: rem-calc(0 30);
    }
  }

  li {
    margin: 0;
    padding: rem-calc(8 7);
    list-style: none;
    font-family: 'ActaDisplay';
    font-size: rem-calc(27);
    line-height: rem-calc(27);
    font-weight: 900;
    font-style: italic;

    @include breakpoint(medium) {
      font-size: rem-calc(36);
      line-height: rem-calc(36);
    }

    a {
      overflow: hidden;
      position: relative;
      display: block;
      padding: rem-calc(0 3);
      color: $color-dark;
      transition: background-color 0.25s $easeOutQuad;

      &::before {
        position: absolute;
        left: 0;
        bottom: 0;
        content: '';
        width: 100%;
        height: 0;
        background-color: $color-main;
        border: rem-calc(1) solid $color-main;
        transform: translateY(101%);
        transition: transform 1s $easeOutExpo;
      }

      &:hover {
        color: $color-main;
      }

      &.active{
        color: $color-main !important;

        &::before {
          transform: translateY(0);
        }
      }
    }
  }
}

.abc-order {
  position: relative;
  margin: rem-calc(0 0 30 0);
  padding: rem-calc(15);
  background: url(../img/bg_pattern_paper.jpg) repeat left top;

  @include breakpoint(medium) {
    padding: rem-calc(60);
  }

  H2 {
    margin-bottom: rem-calc(43);
  }

  &::before {
    display: block;
    position: absolute;
    z-index: 0;
    right: 0;
    top:0;
    content:'';
    width: rem-calc(473);
    height: rem-calc(199);
    background: url(../img/bg_pattern_abc_order.png) no-repeat 106% top;
    background-size: rem-calc(202) auto;

    @include breakpoint(medium) {
      background-size: auto auto;
      background-position: right top;
    }
  }

  .groupe-content {
    position: relative;
    @include flex-grid-row;
    background: #FFFFFF;
    box-shadow: rem-calc(0 15 30 -3) $shadow-medium;
  }
}

.search-nutrients,
.search-clues{
  position: relative;
  margin: rem-calc(0 0 30 0);
  padding: rem-calc(15);
  background: url(../img/bg_pattern_paper.jpg) repeat left top;

  @include breakpoint(medium) {
    padding: rem-calc(50 60 60);
  }

  & > *{
    position: relative;
  }

  &::before {
    display: block;
    position: absolute;
    z-index: 0;
    right: 0;
    top:0;
    content:'';
    width: rem-calc(473);
    height: rem-calc(199);
    background: url(../img/bg_pattern_search_nutrients.png) no-repeat right top;
    background-size: rem-calc(220) auto;

    @include breakpoint(medium) {
      background-size: auto auto;
      background-position: right top;
    }
  }

  form{
    padding: rem-calc(22);
    background: #FFFFFF;
    box-shadow: rem-calc(0 15 30 -3) $shadow-medium;

    @include breakpoint(medium) {
      padding: rem-calc(35);
    }

    & > div {
      position: relative;
      @include flex-grid-row;
      align-items: center;
      width: 100%;
      font-size: rem-calc(17);
      line-height: rem-calc(20);
      font-weight: 300;

      @include breakpoint(medium) {
        font-size: rem-calc(22);
        line-height: rem-calc(22);
      }
    }

    .search-subtitle{
      margin: rem-calc(0 0 3 0);
      flex: 1 100%;
      font-size: rem-calc(19);
      line-height: rem-calc(22);
      color: $color-content;

      @include breakpoint(medium) {
        font-size: rem-calc(26);
        line-height: rem-calc(29);
      }
    }

    select{
      max-width: rem-calc(135);
      margin: rem-calc(0 10 0 0);
      padding: rem-calc(8 36 8 8);
      font-size: rem-calc(19);
      line-height: rem-calc(22);
      font-weight: normal;
      color: $color-dark;
      background-position: 130% center;

      @include breakpoint(medium) {
        font-size: rem-calc(25);
        line-height: rem-calc(29);
      }

      &#edit-nutriment{
        max-width: rem-calc(256);
        background-position: 113% center;
      }
    }

    .separate-word{
      margin: rem-calc(0 10 0 0);
    }

    .form-submit{
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: 0;
      padding: rem-calc(12 42 12 22);
      font-family: 'LemonSans';
      font-size: rem-calc(20);
      line-height: rem-calc(23);
      font-weight: normal;
      color: $color-dark;
      text-transform: none;
      letter-spacing: normal;
      border: none;
      background: darken($color-light, 3%) url(../img/arrow_right_mini_dark.svg) no-repeat 83% center;
      background-size: rem-calc(8 12);
      transition: background-color 0.25s;
      box-shadow: 0 0 0 0 transparent;

      @include breakpoint(small only) {
        position: relative;
        right: auto;
        bottom: auto;
        margin-top: rem-calc(11);
      }

      &:hover{
        color: #FFFFFF;
        background: $color-main url(../img/arrow_right_mini_white.svg) no-repeat 83% center;
        background-size: rem-calc(8 12);
      }
    }
  }
}

.search-clues{
  &::before{
    background: url(../img/bg_pattern_search_clues.png) no-repeat right top;
  }
}

/* ----- page ALIMENT dév ---- */

.node-type-aliments:not(.node-type-articles) .breadcrumb + h1{
  margin-top: rem-calc(30);
  margin-bottom: rem-calc(25);
  font-family: 'LemonSans';
  font-size: rem-calc(70);
  line-height: rem-calc(75);
  font-weight: normal;
  font-style: normal;

  @include breakpoint(medium) {
    font-size: rem-calc(80);
    line-height: rem-calc(85);
    margin-top: 0;
    margin-bottom: rem-calc(53);
  }
}

.node-type-aliments {
  section[role=main] {
    padding: 1em 0 0 0;
  }
}

.node-aliments{
  @include flex-grid-row;
  align-items: flex-end;

  .aliments {
    @include flex-grid-row;
    @include flex-grid-column(12, 0);
    padding: rem-calc(0 0 55 0);
    margin: rem-calc(0 0 38 0);
  }

  .detail-aliment {
    @include flex-grid-column(12);
    // margin-top: rem-calc(-80);
    padding: rem-calc(25 20);
    justify-content: flex-end;
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    box-shadow: rem-calc(0 5 30 -3) $shadow-medium;

    @include breakpoint(medium) {
      @include flex-grid-column(70%, 0);
      margin-top: rem-calc(40);
      padding: rem-calc(35 40);
    }

    @include breakpoint(xlarge) {
      padding: rem-calc(55 60);
      margin-top: 0;
    }
  }

  .valeurs-nutritionnelles {
    @include flex-grid-row;
    justify-content: space-between;
    align-items: center;
    margin: rem-calc(0 0 30 0);
    padding: rem-calc(0 20 20 0);
    font-size: rem-calc(26);
    font-weight: 300;
    color: $color-content;
    border-bottom: rem-calc(1) solid $color-divider;

    @include breakpoint(large) {
      float: right;
      width: rem-calc(380);
    }

    .valeur-nutr {
      display: block;
      font-size: rem-calc(20);
      line-height: rem-calc(22);
      font-weight: 700;
      color: $color-dark;
      text-transform: uppercase;
    }

    .portion {
      font-family: 'ActaDisplay';
      font-weight: 900;
      font-size: rem-calc(36);
      font-style: italic;
      color: $color-dark;

      @include breakpoint(medium) {
        font-size: rem-calc(40);
      }
    }
  }

  .les-valeurs {
    @include breakpoint(medium) {
     @include flex-grid-row;
     width: 100%;
     margin: rem-calc(0 0 20 0);
     padding: 0;
   }
   @include breakpoint(large) {
    flex-wrap: nowrap;
  }

  #donut_single {
    margin-left: rem-calc(-15);
    margin-right: rem-calc(-15);
    overflow: hidden;
    svg {
      [text-anchor="start"][font-size="15"] {
        font-family: 'LemonSans',Arial,sans-serif;
        display: block;
        font-size: 1.25rem;
        line-height: 1.375rem;
        font-weight: 700;
        color: #2e2e30;
        text-transform: uppercase;
      }
    }
    @include breakpoint(medium) {
      margin: 0;
    }
    @include breakpoint(large) {
      @include flex-grid-column(12);
      @include flex-grid-column(8,0);
      flex: 1 0 auto;
      margin-left: 0;
      margin-right: 0;
    }

    & > div{
        //margin-left: -28%;
        //margin: 0 -4em;
        margin-left: -4.5em;
        > div {
          //width: 100% !important;
          //height: auto !important;
        }
        @include breakpoint(small) {
          margin-left: -4em;
          //margin-left: rem-calc(-110);
        }
        @include breakpoint(medium) {
          margin-left: -6.5em;
        }
        @include breakpoint(large) {
          margin-left: -5.5em;
        }
      }
    }

    .details-valeurs {
      padding: 0;
      @include flex-grid-column(12);
      @include breakpoint(large) {
        padding: 0;
      }
      @include breakpoint(large) {
        @include flex-grid-column(4,0);
        order: 2;
      }

      p{
        @include flex-grid-row;
        justify-content:space-between;
        margin-bottom: rem-calc(7);

        a{
          color: $color-main;
        }
      }

      .entitled-donut{
        flex: 3 0;
        font-size: rem-calc(20);
        line-height: rem-calc(20);
      }

      .value-weight-donut{
        width: rem-calc(80);
        font-size: rem-calc(18);
        line-height: rem-calc(18);
        color: $color-content;
        font-weight: 300;
        text-align: right;
      }
    }
  }

  .recalculer{
    padding: rem-calc(0 0 30 0);
    margin: rem-calc(0 0 30 0);
    border-bottom: rem-calc(1) solid $color-divider;

    @include breakpoint(medium) {
      @include flex-grid-row;
      justify-content: flex-end;
      align-items: center;
    }

    .txt-portion {
      font-size: rem-calc(20);
      line-height: rem-calc(20);

      .gr{
        display: block;
        font-size: rem-calc(18);
        font-weight: 300;
        color: $color-content;
      }
    }

    .calcul-portion{

      @include breakpoint(medium) {
        margin: rem-calc(0 0 0 70);
      }

      form{
        @include flex-grid-row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        box-shadow: inset 0 0 0 rem-calc(2) $color-divider;
        margin-top: rem-calc(10);

        @include breakpoint(medium) {
          margin-top: rem-calc(0);
        }
      }

      [type=number]{
        width: rem-calc(120);
        height: rem-calc(56);
        font-size: 'ActaDisplay';
        font-size: rem-calc(33);
        font-weight: 900;
        font-style: italic;
        text-align: center;
        box-shadow: none;
        background: transparent none;

        &:focus{
          border: none;
        }
      }

      [type=submit]{
        cursor: pointer;
        width: rem-calc(50);
        border: none;
        background: transparent url(../img/icon_check_green.svg) no-repeat center center;
        background-size: rem-calc(16 15);
      }
    }
  }

  .ancres{
    @include flex-grid-row;
    justify-content: space-between;
    font-size: rem-calc(19);
    line-height: rem-calc(20);

    & > span{
      margin-right: rem-calc(22);
    }

    .ancres-page{
      @include flex-grid-row;
      justify-content: space-between;
      flex: 1;

      a{
        padding-left: rem-calc(5);
        padding-right: rem-calc(5);
        color: $color-main;
      }
    }
  }

  .other {
    margin-top: rem-calc(30);
    padding-top: rem-calc(30);
    border-top: 1px solid $color-divider;

    .goto-aliment {
      position: relative;
      padding-left: rem-calc(30);
      padding-right: rem-calc(30);
      font-size: rem-calc(20);
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;

      // Glyph
      &::before {
        @include glyph;
        @extend .icon-search:before;
        position: absolute;
        top: 1px;
        left: 0;
        color: $color-main;
      }

      &::after {
        position: absolute;
        content: '';
        top: 2px;
        right: 0;
        margin: rem-calc(0 0 0 8);
        width: rem-calc(21);
        height: rem-calc(14);
        background: transparent url(../img/arrow_right_dark.svg) no-repeat;
        background-size: rem-calc(20) auto;
        transition: transform 0.25s $easeOutQuad;
      }

      &:hover {
        transition: color 0.25s $easeOutQuad;
        color: $color-main;

        &::after {
          transform: translateX(#{rem-calc(8)});
        }
      }
    }

  }

  .img-aliments {
    @include flex-grid-column(12);
    align-self: flex-start;
    position: relative;
    z-index: 0;
    order: -1;

    @include breakpoint(medium) {
      @include flex-grid-column(30%, 0);
      order: 2;
      padding-right: 0 !important;
    }

    @include breakpoint(xlarge) {
      align-self: flex-end;
      margin-bottom: rem-calc(-55);
    }

    img {
      @include breakpoint(medium) {
        width: calc(100% + 60px);
        margin-left: rem-calc(-60);
        max-width: none;
      }

      @include breakpoint(xlarge) {
        width: rem-calc(508);
        max-width: rem-calc(508);
        margin-left: rem-calc(-120);
      }

      @include breakpoint(xxlarge) {
        margin-left: rem-calc(-60);
      }
    }
  }

  .content {
    @include flex-grid-row;
    justify-content: space-between;
  }

  .block-title {
    flex: 1 100%;

    span {
      display: block;
      margin-bottom: rem-calc(7);
      font-size: rem-calc(20);
      line-height: rem-calc(25);
      font-weight: 600;
      text-transform: uppercase;

      &.strong {
        margin: rem-calc( 0 0 25 0);
        font-family: 'ActaDisplay';
        font-size: rem-calc(35);
        line-height: rem-calc(35);
        text-align: left;
        font-weight: 900;
        font-style: italic;
        text-transform: none;

        @include breakpoint(medium) {
          margin: rem-calc( 0 0 56 0);
        }
      }
    }
  }

  .body{
    margin: rem-calc(0 0 75 0);
    p{
      font-size: rem-calc(16);
      line-height: rem-calc(23);
      margin-left: rem-calc(33);
      color: lighten($color-content, 12%);
    }
  }
}

.aliment-poids,
.aliment-sante,
.bloc-calcul-portion {
  @include flex-grid-row;
  align-items: stretch;
  margin: rem-calc(0 0 30 0);

  @include breakpoint(medium) {
    margin: rem-calc(0 0 90 0);
  }

}

.aliment-poids,
.aliment-sante {
  dl {
    @include breakpoint(small only) {
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: rem-calc(30);
      border-bottom: rem-calc(1) solid #ededed;

      &:nth-child(odd) {
        dd {
          border-right: none;
        }
      }
    }
  }
}

.aliment-sante {
  .accordion {
    width: 100%;
  }

  .accordion-title {
    position: relative;
    @include flex-grid-row;
    padding: rem-calc(15 0);
    font-size: rem-calc(24);
    line-height: rem-calc(27);
    color: $color-dark;
    border: none;
    border-bottom: rem-calc(1) solid darken($color-divider, 5%);
    transition: box-shadow .40s $easeOutQuad;
    box-shadow: inset rem-calc(0 0 0 -2) $color-light;
    transition: padding 0.25s $easeOutQuad;

    &:hover,
    &:focus {
      background: transparent none;
    }

    &:hover{
      padding-left: rem-calc(11);
      box-shadow: inset rem-calc(0 70 0 0) $color-light;
    }

    &::before{
      position: absolute;
      right: 4%;
      top: 50%;
      content: '';
      width: rem-calc(12);
      height: rem-calc(8);
      margin: 0;
      background: transparent url(../img/arrow_bottom_red.svg) no-repeat left top;
      background-size: rem-calc(12 8);
      transform: translateY(-50%);
      transition: transform 0.25s $easeOutQuad;

      @at-root{
        .aliment-sante .accordion-item.is-active .accordion-title::before{
          /*background-image: url(../img/arrow_top_red.svg);*/
          transform: rotate(-180deg) translateY(50%);
        }
      }
    }
    .value-anc-homme, .value-anc-femme {
      color: #5b5b63;
      font-weight: 400;
    }

  }
  // .value-anc-femme, .anc-percent:last-child {
  //   display: none;
  // }
  .accordion-inner {
    @include breakpoint(small only) {
      //width: 640px;
    }
  }

  .accordion-content{
    /*display: none;*/
    padding: rem-calc(25 20 50 20);
    color: $color-content;
    font-weight: 300;
    background: $color-light;
    @include breakpoint(medium) {
      font-size: 1.15em;
    }
    ul {
      margin: 0;
    }
    li {
      list-style: none;
      border-bottom: .0625rem dashed #dbdbdb;
      &::after {
        content: '';
        display: table;
        clear: both;
      }
    }
    .indicators-entitled, .global-values {
      li {
        border: none;
      }
    }
    // @include breakpoint(small only) {
    //   overflow-x: scroll;
    // }
  }

  ul.global-values {
    list-style: none;
    margin: 0;

    li {
      @include flex-grid-row;
      .value-anc-homme,
      .value-anc-femme {
        @include flex-grid-column(2, 0);
        text-align: right;
        @include breakpoint(medium only) {
          @include flex-grid-column(3, 0);
        }
      }

    }
    > li {
      > .entitled, > .value-weight, .value-anc-homme, .value-anc-femme {
        font-size: 1.1em;
        font-weight: 600;
        color:#5b5b63;
      }
    }
  }
}

ul.indicators-entitled {
  @include flex-grid-row;
  list-style: none;
  font-size: rem-calc(15);
  text-align: right;

  li{
    text-align: right;

    &.portion{
      @include flex-grid-column(8, 0);

      @include breakpoint(medium only) {
        @include flex-grid-column(6, 0);
      }
    }

    &.anc-percent {
      @include flex-grid-column(2, 0);
      flex-grow: 1;
      @include breakpoint(medium only) {
        @include flex-grid-column(3, 0);
      }
    }
  }
}

.entitled {
  @include flex-grid-column(6, 0);

  .child-values & {
    padding-left: rem-calc(12);
  }

  .global-values > li > & {
    text-transform: uppercase;
    font-weight: 500;
    border-bottom: rem-calc(1) solid darken($color-divider, 7%);
  }

  @include breakpoint(medium only) {
    @include flex-grid-column(4, 0);
  }
}

.value-weight {
  @include flex-grid-column(2, 0);
  padding: rem-calc(4 0 0 0);
  // font-size: rem-calc(15);
  // line-height: rem-calc(15);
  font-weight: 300;
  text-align: right;
  float: right;
  @at-root {
    .node-aliments .accordion-title .value-weight{
      padding-top: rem-calc(8);
      padding-right: rem-calc(7);
      font-size: rem-calc(18);
      line-height: rem-calc(19);
    }
  }

  .global-values > li > & {
    border-bottom: rem-calc(1) solid darken($color-divider, 7%);
  }

}

.child-values{
  display: block;
  width: 100%;
  > ul {
    > li {
      > .entitled {
        color: #5b5b63;

      }
      > .value-weight {
        color: #5b5b63;
        font-weight: 400;
      }
    }
  }
  .entitled {
    line-height: 1;
    padding: .5em 0;
  }

  ul {
    display: block;
    width: 100%;
    margin-left: 0;
    li {
      .entitled,
      .value-weight {
        border-bottom: rem-calc(1) dashed darken($color-divider, 7%);
      }
      .entitled {
        position: relative;
        // &::after {
        //   content: "";
        //   position: absolute;
        //   bottom: rem-calc(-1);
        //   left: 0;
        //   width: rem-calc(12);
        //   height: rem-calc(1);
        //   background: $color-light;
        // }
      }
    }

    & ul {
      li {
        line-height: 1.6;
        .entitled,
        .value-weight {
          padding: 0;
          border-bottom: rem-calc(1) dotted darken($color-divider, 5%);
        }
        .entitled {
          padding-left: .5rem;
          padding-top: .3em;
          padding-bottom: 0;
          font-size: .95em;
          //line-height: inherit;
          &::after {
            width: rem-calc(24);
          }
        }
      }
      & ul {
        width: 100%;
        li {
          padding: rem-calc(1 0);
          border: none;
          .entitled,
          .value-weight {
            border-bottom: none;
          }
          &:last-child {
            .entitled,
            .value-weight {
              border-bottom: rem-calc(1) dashed darken($color-divider, 7%);
            }
          }
          .entitled {
            padding-left: 1em;
          }
        }
      }
    }
  }
}

.bloc-calcul-portion{
  @include flex-grid-row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin: rem-calc(0 0 51 0);
  padding: rem-calc(25 30 46 110);
  background: url(../img/bg_pattern_recherche_aliment.jpg) no-repeat left top;

  @include breakpoint(medium) {
    padding: rem-calc(45 68 46 153);
  }

  .title{
    @include flex-grid-column(12, 0);
    max-width: 100%;
    margin: auto;
    margin-bottom: rem-calc(5);
    font-family: 'ActaDisplay';
    font-size: rem-calc(34);
    line-height: rem-calc(34);
    font-weight: 900;
    font-style: italic;

    @include breakpoint(medium) {
      font-size: rem-calc(40);
      line-height: rem-calc(40);
    }
  }
  .subtitle{
    display: block;
    margin-bottom: rem-calc(7);
    font-size: rem-calc(20);
    line-height: rem-calc(25);
    text-transform: uppercase;
  }


  .unite{
    font-size: rem-calc(18);
    line-height: rem-calc(22);
    font-weight: 300;
    color: $color-content;

    @include breakpoint(medium) {
      display: block;
    }
  }

  form{
    @include flex-grid-row;
    flex-wrap: nowrap;
    margin-right: 0;
    background: #FFFFFF;
    box-shadow: inset 0 0 0 rem-calc(2) $color-divider;

    @include breakpoint(small only) {
      margin: 0;
      padding: 0;
      width: 100%;
      justify-content: flex-end;
    }
  }

  [type=number]{
    width: rem-calc(120);
    height: rem-calc(56);
    font-family: 'ActaDisplay';
    font-size: rem-calc(33);
    font-weight: 900;
    font-style: italic;
    text-align: center;
    box-shadow: none;
    background: transparent none;

    &:focus{
      border: none;
    }
  }

  [type=submit]{
    cursor: pointer;
    width: rem-calc(50);
    border: none;
    background: transparent url(../img/icon_check_green.svg) no-repeat center center;
    background-size: rem-calc(16 15);
    transition: transform .25s $easeOutBack;
    border: none;
    box-shadow: none;

    &:hover {
      transform: scale(1.2);
    }
  }
}

dl {
  @include flex-grid-row;
  @include flex-grid-column(3, 0);
  justify-content: center;
  text-align: center;
  align-items: flex-end;
  margin: 0;

  span {
    display: block;
    font-size: rem-calc(20);
    line-height: rem-calc(27);
  }

  dt {
    align-self: flex-start;
    font-family: 'LemonSans';
    font-size: rem-calc(24);
    line-height: rem-calc(27);
    font-weight: normal;
  }

  dd {
    @include flex-grid-column(12, 0);
    align-self: flex-end;
    font-size: rem-calc(22);
    line-height: rem-calc(23);
    border-right: rem-calc(1) solid $color-divider;

    @at-root{
      .node-aliments dl:last-child dd{
        border: none;
      }
    }
  }
}

.donnees {
  @include flex-grid-row;
  justify-content: center;
  align-items: baseline;
  padding: rem-calc(0 0 15 0);
  border-bottom: rem-calc(1) solid $color-divider;

  .nombre {
    margin: rem-calc(0 11 0 0);
    font-size: rem-calc(60);
    line-height: rem-calc(62);
    font-weight: 600;

    @include breakpoint(medium){
      font-size: rem-calc(70);
      line-height: rem-calc(73);
    }

    @at-root {
      .low .nombre,
      .very-low .nombre {
        color: $color-low;
      }

      .moderate .nombre {
        color: $color-moderate;
      }

      .high .nombre,
      .very-high .nombre {
        color: $color-high;
      }
    }
  }
}

.mesure {
  padding: rem-calc(22 0 25 0);
  height: 3.25em;
  @at-root {
    .low .mesure,
    .very-low .mesure {
      color: $color-low;
    }

    .moderate .mesure {
      color: $color-moderate;
    }

    .high .mesure,
    .very-high .mesure {
      color: $color-high;
    }
  }
}

// Foundation c'est écraser une mouche avec un tank :)

.ln__anc__item {
  display: flex;
  justify-content: space-between;
  border-bottom: .0625rem solid #dbdbdb;
  .value-weight, .entitled {
    font-size: 1.1em;
    font-weight: 600;
    color: #5b5b63;
  }
  &:first-child {
    margin-top: 1em;
  }
  @include breakpoint(large) {
    display: none;
  }
}


// Foundation c'est le mal

.aliment-sante {
  [class^="icon-"]:before, // ???
  [class*=" icon-"]:before {
    font-family: "nutricons" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.accordion-inner {
  font-size: 18px;
  .values__anc {
    font-size: .9em;
    font-weight: bold;
    i {
      display: inline-block;
      vertical-align: middle;
      font-style: .9em;
      margin-right: .05em;
    }
  }
  .value__anc {
    margin: 0 .1em;
    &::before {
      content: '-';
      margin-right: .05em;
    }
    &:first-child {
      &::before {
        content: none;
      }
    }
  }
  .indicators-entitled {
    > .portion {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .global-values {
    > li {
      .entitled, .value-weight {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}

.anc__legend {
  background-color: #f1f1f1;
  width: 100%;
  padding: .5em 1em;
  margin-bottom: 1em;
  strong {
    display: block;
  }
}
.anc__legend__item {
  font-weight: 300;
  display: block;
  font-size: .9em;
  color: #777;
}
