/* ==========================================================================
habillage_publicitaire.scss
========================================================================== */

.advertising{
  .advertising-cladding{
    display: block;
    position: absolute;
    z-index: 0;
    left: 50%;
    cursor: pointer;
    top: rem-calc(0);
    width: rem-calc(1800);
    height: rem-calc(1200);
    // background: url('../img/habillage_publicitaire_lanutrition.jpg') no-repeat center top;
    background-repeat: no-repeat;
    background-position: center top;
    transform: translateX(-50%);

    @include breakpoint(small only) {
      display: none;
    }
  }

  .advertising-cladding-mobile{
      position: relative;
      z-index: 0;
      display: block;
      cursor: pointer;
      width: 100%;
      height: auto;
      margin-bottom: rem-calc(30);

    @include breakpoint(medium) {
      display: none;
    }

    .secondary-nav + &{

      @include breakpoint(small only) {
        margin-top: rem-calc(44);
      }

      @include breakpoint(medium only) {
        margin-top: rem-calc(44);
      }
    }
  }

  section[role=main]{
    &::before{
      display: none;
    }

    @include breakpoint(small only) {
      padding-top: rem-calc(0);
    }

    .node-type-aliments &,
    .section-les-news &{
      @include breakpoint(small only) {
        padding-top: 0;
      }
      @include breakpoint(medium only) {
        padding-top: 0;
      }
    }
  }

  .focus{
    .view-content {
      padding-top: rem-calc(25);

      @include breakpoint(medium) {
        padding-top: rem-calc(77);
      }
    }
  }

  .container{
    position: relative;
    @include breakpoint(medium) {
      padding-top: rem-calc(161);
      padding-left: rem-calc(15);
      padding-right: rem-calc(15);
    }

    @include breakpoint(large) {
      padding-top: rem-calc(141);
    }

    .page-user &,
    .page-edition-abonnes &{
      @include breakpoint(medium) {
        padding-top: rem-calc(168);
      }

      @include breakpoint(large) {
        padding-top: rem-calc(138);
      }
    }

    .node-type-aliments &{
      @include breakpoint(medium) {
        padding-top: rem-calc(222);
      }

      @include breakpoint(large) {
        padding-top: rem-calc(202);
      }
    }

    .section-les-news &{
      @include breakpoint(medium) {
        padding-top: rem-calc(222);
      }

      @include breakpoint(large) {
        padding-top: rem-calc(138);
      }
    }
  }

  .focus {
    .views-row{
      &.views-row-first{
        &::before{
          display: none;
        }
      }
    }
  }

}
