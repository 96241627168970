/* ==========================================================================
  messages.scss
========================================================================== */

.messages{
  position: relative;
  width: 100%;
  max-width: rem-calc(970);
  margin: auto;
  margin-bottom: rem-calc(22);
  padding: rem-calc(20 30 20 30);
  font-size: rem-calc(18);
  color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: rem-calc(18 18);
  border-width: rem-calc(3);

  &__icon{
    display: none;
  }

  &--status{
      border-color: #00a975;
      background-color: #02b994;

    a.preferences-link {
      color: #0a0a0a;
      text-decoration: underline;
    }
  }

  &--error{
      border-color: #e0001a;
      background-color: #e63247;
  }

  &--warning {
      border-color: #e59501;
      background-color: #ECAF3F;
  }
}
