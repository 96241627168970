
.tabs {
    list-style: none;
    max-width: rem-calc(1170);
    padding: 0;
    margin: auto;
    border-bottom: solid $color-content 1px;
}

/*.tabs-title {
    padding: rem-calc(10 15);
    display: block;
    text-align: center;
    &:not(:first-child) {
        margin-left: -0.34em;
    }
    a {
        font-size: 14px;
        font-family: "Titillium Web";
        color: $color-healthcare;
        line-height: rem-calc(30);
    }
    &.is-active {
        padding: rem-calc(15 20);
        a {
            font-size: rem-calc(16);
            color: $color-main;
            font-weight: bold;
        }
    }

    @media (min-width: rem-calc(640)) {
        padding: rem-calc(15 20);
        &.is-active {
            padding: rem-calc(20 25);
        }
    }

    @media (min-width: rem-calc(480)) {
        display: inline-block;
        vertical-align: bottom;
        border: solid $color-content 1px;
        border-bottom: none;
        text-align: left;
        &.is-active {
            border-bottom: solid #FFF 1px;
            margin-bottom: -1px;
        }
    }
}


body:not(.page-user) {
    .tabs-primary, .action-links {
        width: 100%;
        margin-bottom: rem-calc(30);

        @media (min-width: rem-calc(1200)) {
            width: rem-calc(1200);
            margin: 0 auto rem-calc(30) auto;
        }
    }
}

.tabs-primary {
    list-style: none;
    padding: 0;
    margin: rem-calc(0 0 50 0);
    border-bottom: solid $color-divider 1px;

    li.tabs-primary__tab {
        display: block;
        text-align: center;
        margin-left: 0;
        &:not(:first-child) {
            margin-left: -0.34em;
        }
        a {
            display: block;
            box-sizing: border-box;
            font-size: 14px;
            font-family: "Titillium Web";
            color: $color-content;
            line-height: rem-calc(30);
            border: none;
            padding: rem-calc(5 15);
        }
        &.is-active a {
            padding: rem-calc(13 15);
            font-size: rem-calc(16);
            font-weight: bold;
        }
        a:hover, &.is-active a {
            background: none;
            color: $color-main;
            border-top: solid $color-main rem-calc(2);
        }

        @media (min-width: rem-calc(640)) {
            a {
                padding: rem-calc(10 20);
            }
            &.is-active a {
                padding: rem-calc(18 20);
            }
        }

        @media (min-width: rem-calc(480)) {
            display: inline-block;
            vertical-align: bottom;
            border: solid $color-content 1px;
            border-bottom: none;
            text-align: left;
            &.is-active {
                border-bottom: solid #FFF 1px;
                margin-bottom: -1px;
            }
        }
    }
}

.action-links {
    padding: 0;
    a {
        padding: 0;
        border: none;
        font-size: rem-calc(13);
        font-family: "AvenirNext LT Pro";
        color: $color-healthcare;
        font-weight: bold;
        text-transform: uppercase;
        line-height: rem-calc(1);

        &:before {
            @include fontIcon();
            color: $color-main;
            display: inline-block;
            vertical-align: middle;
            line-height: rem-calc(24);
            margin-right: rem-calc(10);
        }

        &:hover, &:focus {
            background: none;
            color: $color-main;
            transform: translateX(rem-calc(15));
        }
    }
}*/
