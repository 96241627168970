/* ==========================================================================
  pre-footer.scss
========================================================================== */

.region-pre-footer {
  @include flex-grid-row;
  position: relative;
  z-index: 1;
  margin-bottom: rem-calc(-195);

  @include breakpoint(medium) {
    padding-left: rem-calc(15);
    padding-right: rem-calc(15);
  }

  @include breakpoint(xlarge) {
    padding-left: rem-calc(0);
    padding-right: rem-calc(0);
  }

  #block-block-3,
  #block-block-4 {
    @include flex-grid-column(12);
    text-align: center;

    @include breakpoint(medium) {
      @include flex-grid-size(6);
    }
  }

  #block-block-3 {
    @include flex-grid-column(12);

    margin-bottom: rem-calc(30);
    color: #FFFFFF;

    @include breakpoint(medium) {
      @include flex-grid-column(6, 0);
      margin-top: rem-calc(60);
      margin-bottom: 0;
    }
  }

  #block-block-4 {
    margin-bottom: rem-calc(30);
    padding: 0;
    color: #FFFFFF;

    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  .push {
    background: $color-main url(../img/bg_pattern_boutique.png) repeat left top;

    &--shop {
      padding: rem-calc(45 10 40 10);

      @include breakpoint(medium) {
        padding: rem-calc(45 0 40 0);
      }

      &__content {
        img {
          margin-bottom: rem-calc(7);
        }

        p{
          margin-bottom: rem-calc(23);
        }
      }

      &__title {
        margin: auto;
        margin-bottom: rem-calc(7);
        font-family: 'ActaDisplay';
        font-size: rem-calc(27);
        line-height: rem-calc(27);
        font-weight: 900;
        font-style: italic;

        @include breakpoint(medium) {
          font-size: rem-calc(36);
          line-height: rem-calc(36);
        }
      }
    }

    &--subscribe {
      width: calc(100% - 26px);
      margin: auto;
      box-shadow: rem-calc(0 0 0 13) #FFFFFF;
      border: rem-calc(13) solid transparent;
      border-image: url(../img/bg_pattern_stripes.png) 13 round;
      border-image-outset: 1;
      background: #FFFFFF url(../img/bg_pasabonne.jpg) repeat rem-calc(-13 -13);

      &__content{
        padding: rem-calc(80 0 56 0);

        & > p{
          max-width: auto;
        }
      }

      &__title {
        margin: auto;
        margin-bottom: rem-calc(10);
        font-family: 'ActaDisplay';
        font-size: rem-calc(27);
        line-height: rem-calc(27);
        font-weight: 900;
        font-style: italic;
        color: $color-dark;

        @include breakpoint(medium) {
          font-size: rem-calc(45);
          line-height: rem-calc(45);
        }
      }

      p{
        color: $color-dark;
      }
    }

    p {
      max-width: rem-calc(420);
      margin-left: auto;
      margin-right: auto;
      font-size: rem-calc(21);
      line-height: 1;
    }
  }
}
