/* ==========================================================================
  search.scss
========================================================================== */

.page-search-node{

  // .search-form{
  //   display: none;
  // }

  h2{
    flex: 1 100%;
    margin-bottom: rem-calc(15);
    font-size: rem-calc(40);
    line-height: rem-calc(40);
    font-weight: 500;
  }
}

.search-results{
  @include flex-grid-column(12, 0);
  justify-content: space-between;

  @include breakpoint(medium){
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
  }
  .custom_search_results{
    @include flex-align(justify, top);
  }

  & + h2.element-invisible{
    display: none;
  }
}

.custom-search-filter {
  h3 {
    text-align: left;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    display: inline-block;
    vertical-align: middle;
    > a {
      border-radius: 3px;
      padding: .15em .75em;
      border: solid #df5858 1px;
    }
  }
}


/* PAGE RECHERCHE ALIMENT */

.page-recherche-aliments {
  .container {
    text-align: center;

    ul:not(.tabs) {
      max-width: rem-calc(850);
      margin: auto;
      margin-bottom: rem-calc(95);
      list-style: none;

      li {
        margin: rem-calc(0 3 10);
        text-transform: uppercase;

        a {
          display: inline-block;
          margin: 0;
          padding: rem-calc(3 13);
          font-size: rem-calc(20);
          color: $color-dark;
          font-weight: 500;
          box-shadow: inset rem-calc(0 0 0 2) #ededed;
          border-radius: rem-calc(20);
          transition: color .1s, box-shadow .2s;

          &:hover {
            color: #FFFFFF;
            background-color: $color-main;
            box-shadow: inset rem-calc(0 0 0 2) darken($color-main, 5%);
          }
        }
      }
    }
  }

  a.more-articles{
    display: inline-block;
    justify-content: center;
    margin: auto;
    margin-bottom: rem-calc(80);
    padding: rem-calc(12 22);
    font-size: rem-calc(16);
    font-weight: 600;
    color: $color-dark;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: rem-calc(1);
    border: none;
    background: none;
    border-radius: rem-calc(3);
    box-shadow: inset 0 0 0 0 $color-main, inset rem-calc(0 0 0 2) $color-dark;
    transition: box-shadow .40s $easeOutQuad, color .25s $easeOutQuad;

    &:hover {
      color: #ffffff;
      box-shadow: inset rem-calc(300 0 0 0) $color-main, inset rem-calc(0 0 0 2) $color-dark;
      background-color: $color-main;
    }
  }
}

.section-search {
  section[role=main] {
    padding-top: 1em;
    &::before {
      height: 15em;
    }
  }
  .breadcrumb {
    margin-bottom: 0;
  }
  .search-form {
    margin: 0 0 5.5em 0;
    padding: 0 1em;
    text-align: center;
    label {
      font-size: 1.15em;
    }
  }
  .form-text {
    margin-bottom: .5em;
  }
  .form-submit {
    display: inline-block;
  }
  .search-results {
    margin: 0;
    figure {
      margin-bottom: 1rem;
    }
    .preview-article__title {
      margin-top: 0;
    }
  }
  .form-wrapper {
    margin-bottom: 3em;
  }
  .content {
    padding: 0 1rem;
  }
  .page-title {
    margin-bottom: .25rem;
  }
  .criterion {
    .parent {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 .5em .5em;
      label {
        margin: 0;
        font-size: 1.25rem;
        padding: .25em 1em .4em 1em;
        box-shadow: inset 0 0 0 0.125rem #ccc;
        border-radius: 1.25rem;
        transition: color .1s,box-shadow .2s;
        position: relative;
        line-height: 1;
        &::before {
          content: "";
          border-radius: 100%;
          background-color: #4db356;
          box-shadow: 0 0 0 0.1875rem #f1f2f5;
          position: absolute;
          width: 1.375rem;
          height: 1.375rem;
          top: -.25rem;
          right: -.5rem;
          transform: scale(0);
          transition: transform .2s cubic-bezier(.175,.885,.32,1.275);
        }
        &::after {
         @include glyph;
         @extend .icon-checkmark:before;
         position: absolute;
         color: #fff;
         font-size: 1rem;
         top: -.0625rem;
         right: -.3em;
         transform: scale(0);
         transition: transform .2s cubic-bezier(.175,.885,.32,1.275);
       }
     }
     .form-checkbox {
      @extend .visually-hidden;
      &:checked + label {
        color: #4db356;
        box-shadow: inset 0 0 0 0.125rem rgba(77,179,86,.8);
        &::before {
          transform: scale(1);
        }
        &::after {
          transform: scale(1);
        }
      }
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
@include breakpoint(medium){
  section[role=main] {
    &::before {
      height: 20em;
    }
  }
  .form-wrapper {
    margin-bottom: 0;
  }
  .search-form {
    margin: 0 -1em 5.5em -1em;
    text-align: left;
    .form-wrapper {
      position: relative;
    }
    .form-text {
      padding-right: 7em;
    }
    .form-submit {
      height: 2.875rem;
      position: absolute;
      right: 0;
      bottom: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
}
