/* ==========================================================================
forms.scss
========================================================================== */

[type="name"],
[type="color"],
[type="date"],
[type="datetime-local"],
[type="datetime"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
textarea {
  display: block;
  width: 100%;
  height: rem-calc(46);
  padding: rem-calc(0 30);
  margin: 0;
  background: #FFFFFF;
  font-family: 'LemonSans';
  font-size: rem-calc(18);
  font-weight: 300;
  color: $color-content;
  border-radius: rem-calc(1);
  border: none;
  box-shadow: inset 0 0 0 rem-calc(1) $color-divider;
  transition: box-shadow .25s $easeOutExpo, color .25s $easeOutExpo, background .25s $easeOutExpo;
  border-radius: rem-calc(2);

  &:focus {
    background: #FFF;
    outline: none;
  }
}

textarea{
  padding-top: rem-calc(22);
  padding-bottom: rem-calc(22);
}

/* MANAGED FILE */

.image-widget{
  @include flex-grid-row;
  height: rem-calc(46);

  @include breakpoint(small only) {
    height: auto;
  }

  .image-widget-data{
    padding: rem-calc(10);
    box-shadow: inset 0 0 0 rem-calc(1) $color-divider;

    @include breakpoint(medium) {
      @include flex-grid-row;
      @include flex-grid-column(12, 0);
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding: rem-calc(0 0 0 10);
    }
  }

  &::before,
  &::after{
    display: none;
  }
}


// Form text
.form-text {

  &:hover {

  }

  &:focus {

  }
}

// Textarea
.form-textarea-wrapper {

  .form-textarea {

    &:hover {

    }

    &:focus {

    }
  }
}




// Select
select {
  width: auto;
  height: rem-calc(50);
  font-family: 'LemonSans';
  font-size: rem-calc(18);
  font-weight: 300;
  color: $color-content;
  background-image: url(../img/arrow_bottom_red.svg);
  background-size: rem-calc(12 8);
  background-position: 98% center;
  border: none;

  &:hover {

  }

  &:focus {

  }

  option {
    position: relative;
  }
}

// Input submit
[type="submit"] {
  @extend .button;
}

/* RADIOS */

.form-radios{

  .form-type-radio{
    display: inline-block;
  }
}


// Webform
form.webform-client-form{

  // Container of each element
  .webform-component{

    // Labels
    > label {

    }

    .form-item {
      label {

      }
    }
  }

  // Select
  select {

    option {

    }
  }

  // Form text
  .form-text {

    &:hover {

    }

    &:focus {

    }
  }

  // Textarea
  .webform-component-textarea {

  }
  .form-textarea-wrapper {

    .form-textarea {

      &:hover {

      }

      &:focus {

      }
    }
  }

  // Radios
  .webform-component-radios {

    label ~ .form-radios {

    }

    .form-type-radio {
      label {

      }
    }
  }

  // Checkboxes
  .webform-component-checkboxes {

    label ~ .form-checkboxes {

    }

    .form-type-checkbox {

      label {

      }
    }
  }

  // Upload file
  .form-managed-file {

    .form-file {

    }

    .form-submit {

      &:hover {

      }
    }
  }

  // Description Field
  .description {

  }

  // Inline content
  .webform-container-inline{


    &.webform-component {

      // Labels
      > label {

      }
    }

    // Form text
    .form-text {

    }

    // Select
    select {

      &:hover{

      }
    }

    // Textarea
    .form-textarea-wrapper {

      .form-textarea {

      }
    }


    // Upload file
    .form-managed-file {

    }

    // Radios
    .form-type-radio{

    }

  }

  // Button
  .form-actions {
    .webform-submit {

      &:hover {

      }
    }
  }

}
