/* ==========================================================================
  pages.scss
========================================================================== */
section[role=main] {
  padding-top: 5em;
  @include breakpoint(medium){
    padding-top: 3.5em;
  }
  @include breakpoint(large){
    padding-top: 5em;
  }
  &.autre {
    padding-top: 1em;
  }
}

.social-icons{
  @include menu-base;

  .has-tip{
    cursor: pointer;
  }

  // In Footer
  .footer &{
    @include flex-align(center, top);
  }

  & > li{

    &:not(:first-child){
      margin-left: rem-calc(16);
    }
  }

  // Override Foundation
  & > li > a{
    padding: 0;
    width: rem-calc(40);
    height: rem-calc(40);
    line-height: rem-calc(40);
    background-color: #f1f1f1;
    border-radius: 100%;
    overflow: hidden;
    border: none;
    transition: color .2s, background-color .2s, transform .2s $easeOutBack;
    color: $color-darker;

    // In Footer
    .footer &{
      background-color: #fff;
    }

    // Icon
    position: relative;
    &::after{
      @include glyph;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: rem-calc(16);
      transform: translate(-50%, -50%);
    }
    &.fb::after{
      @extend .icon-facebook:before;
    }
    &.tw::after{
      @extend .icon-twitter:before;
    }
    &.pi::after {
      @extend .icon-pinterest:before;
    }
    &.in::after {
      @extend .icon-instagram:before;
    }
    &.rss::after {
      @extend .icon-rss:before;
      font-size: rem-calc(14);
    }

    // Hover
    &:hover{
      color: $color-main;
      transform: scale(1.2);
    }
  }

  // Label Inside hidden
  span{
    display: block;
    text-indent: 101%;
  }
}

.pager{
  @include flex-grid-row;
  justify-content: center;

  &--infinite-scroll{
    list-style: none;
    margin-top: rem-calc(15);

    @include breakpoint(medium) {
      margin-top: rem-calc(30);
    }
  }

  &__item{
    a{
      display: block;
      margin: 0;
      padding: rem-calc(12 22);
      font-size: rem-calc(16);
      font-weight: 600;
      color: $color-dark;
      text-transform: uppercase;
      letter-spacing: rem-calc(1);
      border: none;
      background: none;
      border-radius: rem-calc(3);
      box-shadow: inset 0 0 0 0 $color-main, inset rem-calc(0 0 0 2) $color-dark;
      transition: box-shadow .40s $easeOutQuad, color .25s $easeOutQuad;

      &:hover {
        color: #ffffff;
        box-shadow: inset rem-calc(300 0 0 0) $color-main, inset rem-calc(0 0 0 2) $color-dark;
        background-color: $color-main;
      }
    }
  }
}

.content {
  position: relative;
}

.-is-sticky {
  &.-is-sticked {
    position: fixed;
    top: 100px;
    &.-is-bottom {
      position: absolute;
      top: auto !important;
      bottom: 0;
      right: 0;
      left: auto !important;
    }
  }
}
