/* ==========================================================================
  nav.scss
========================================================================== */

.navigation {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}
