/* ==========================================================================
pagination.scss
========================================================================== */

/* Pagination */
.item-list {
  margin: rem-calc(25 0);
  text-align: center;

  ul.pager {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin: rem-calc(20 0);
    list-style: none;

    li {
      padding: 0;
      margin: rem-calc(3);
      width: rem-calc(44);
      height: rem-calc(44);
      line-height: rem-calc(42);
      font-family: 'LemonSans';
      font-size: rem-calc(18);
      font-weight: 600;
      border-radius: 50%;
      overflow: hidden;

      a {
        display: block;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        align-items: center;
        justify-content: center;
        color: $color-dark;
        font-size: inherit;
        font-weight: inherit;
        box-shadow: inset 0 0 0 1px #e5e5e5;
        transition: color .2s, box-shadow .2s, background .2s;

        &:hover,
        &:focus {
          box-shadow: inset 0 0 0 1px $color-main;
          color: $color-main;
        }
      }

      // Hide Texts to set icons
      &.pager-previous,
      &.pager-next,
      &.pager-first,
      &.pager-last {
        position: relative;
        text-indent: 100%;
        transition: color .2s, box-shadow .2s, background .2s;

        &::before,
        &::after {
          @include glyph;
          font-size: rem-calc(11);
          text-indent: 0;
          pointer-events: none;
          position: absolute;
          top: 50%;
          left: 50%;
        }

        &:hover,
        &:focus {
          color: $color-main;
        }
      }

      // Prev/Next Icons
      &.pager-previous::after {
        @extend .icon-arrow-thickline-left:before;
        transform: translate(-55%, -50%);
      }
      &.pager-next::after {
        @extend .icon-arrow-thickline-right:before;
        transform: translate(-45%, -50%);
      }

      // First/Last Icons
      &.pager-first::before,
      &.pager-first::after {
        @extend .icon-arrow-thickline-left:before;
      }
      &.pager-first::before { transform: translate(-80%, -50%); }
      &.pager-first::after { transform: translate(-30%, -50%); }

      &.pager-last::before,
      &.pager-last::after {
        @extend .icon-arrow-thickline-right:before;
        font-size: rem-calc(11);
      }
      &.pager-last::before { transform: translate(-70%, -50%); }
      &.pager-last::after { transform: translate(-20%, -50%); }

      // Ellipsis
      &.pager-ellipsis {
        box-shadow: none;
        color: $color-content;
      }

      // Current
      &.pager-current {
        color: #fff;
        background: $color-main;
        // box-shadow: inset 0 0 0 1px $color-main;
      }

      // Mobile
      &.pager-ellipsis {
        @include hide-for(small only);
      }
      &.pager-first {

        @include breakpoint(small only) {
          margin-left: calc(50% - #{rem-calc(47)});
          order: 1;
        }
      }
      &.pager-last {

        @include breakpoint(small only) {
          margin-right: calc(50% - #{rem-calc(47)});
          order: 2;
        }
      }
    }
  }
}
