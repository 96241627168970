/* ==========================================================================
  footer.scss
========================================================================== */

.container--footer{
  background: #F1F1F1;
}

.footer {
  @include flex-grid-row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: rem-calc(258 15 20 15);

  @include breakpoint(xlarge) {
    padding-left: rem-calc(0);
    padding-right: rem-calc(0);
  }

  #block-block-6 {
    @include flex-grid-column(12);
    margin: rem-calc(0 0 40 0);
    padding: rem-calc(0 0 40 0);
    text-align: center;
    border-bottom: rem-calc(1) solid #dddddd;

    @include breakpoint(medium) {}

    img.logo-nutrition{
      margin-bottom: rem-calc(17);
    }
  }

  #block-block-5{
    @include flex-grid-column(12);
    margin-bottom: rem-calc(22);
    padding-left: 0 !important;
    font-size: rem-calc(15);
    line-height: rem-calc(23);
    font-weight: 300;
    color: $color-content;

    @include breakpoint(large) {
      @include flex-grid-column(9);
    }

    @include breakpoint(medium) {
      margin-bottom: 0;
      font-size: rem-calc(18);
      line-height: rem-calc(22);
    }

    > p{
      margin-bottom: rem-calc(38);
      line-height: inherit;
    }

    .list-categories{
      @include flex-grid-row;

      .categorie{
          @include flex-grid-column(12);
          margin-bottom: rem-calc(10);

          @include breakpoint(medium) {
            @include flex-grid-size(4);
            margin-bottom: rem-calc(22);
          }

        &:not(:nth-child(3n+2)){

          @include breakpoint(medium) {
            @include flex-grid-column(4, 0);
          }
        }

        p{
          line-height: inherit;
        }

        > a:first-child{
          position: relative;
          display: inline-block;
          overflow: hidden;
          font-size: rem-calc(17);
          line-height: rem-calc(20);
          font-weight: 500;
          color: $color-dark;
          text-transform: uppercase;

          @include breakpoint(medium) {
            font-size: rem-calc(20);
            line-height: rem-calc(25);
          }

          &::before{
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            width: 100%;
            height: 0;
            border: rem-calc(1) solid $color-dark;
            transform: translateX(-101%);
            transition: transform 0.25s $easeOutQuad, color 0.60s;
          }

          &:hover{
            color: $color-dark;
            &::before{
              transform: translateX(0);
            }
          }
        }

        p{

        }
      }
    }
  }

  #block-block-8{

  }
}
