/* ==========================================================================
columns.scss
========================================================================== */

.left {
  @include flex-grid-column(12);
  margin-bottom: rem-calc(44);

  @include breakpoint(large) {
    @include flex-grid-column(65%, 0);
    margin-bottom: rem-calc(44);
  }

  .categ-level-2 & {}

  .categ-level-3 &,
  .featured--newsletter &,
  .articles .articles__content &{
    padding-left: rem-calc(15);
    padding-right: rem-calc(15);

    @include breakpoint(medium) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .node-aliments &{
    padding-left: 4%;
  }

  .page-page-auteur & {
    order: 2;
    margin-bottom: rem-calc(110);

    @include breakpoint(medium) {
      order: initial;
    }
  }

  .page-les-aliments-a-la-loupe &{
    @include flex-grid-column(12, 0);

    @include breakpoint(large) {
      @include flex-grid-column(65%, 0);
    }
  }
}

.right {
  @include flex-grid-column(12, 0);

  @include breakpoint(large) {
    @include flex-grid-column(28%, 0);
    padding-right: 0 !important;
  }

  .section-terms &,
  .categ-level-1 &,
  .categ-level-2 & {
    padding-top: rem-calc(0);

    @include breakpoint(medium) {
      padding-top: rem-calc(30);
    }
  }

  .categ-level-3 & {
    padding-top: rem-calc(30);
  }

  .page-page-auteur & {
    order: 1;
    margin-top: rem-calc(0);
    margin-bottom: rem-calc(30);

    @include breakpoint(medium) {
      order: initial;
      margin-top: rem-calc(-245);
    }
  }

  .node-results &{
    margin-top: rem-calc(55);
  }

  /*.page-page-auteur & {
    @include breakpoint(medium) {
      @include flex-grid-column(30%, 0);
    }
  }*/

  &.articles-summary {
    z-index: 2;
    order: 2;
  }
}
