/* ==========================================================================
  homepage.scss
=========================================================================== */

.focus {
  padding-bottom: rem-calc(30);

  @include breakpoint(medium) {
    padding-bottom: rem-calc(75);
  }

  .view-articles {
    position: relative;
    max-width: $max-width-grid;
    margin: 0 auto;

    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: rem-calc(570);
    //   background: url('../img/bg_pattern_home.png') left top;
    //   background-color: darken($color-light, 1.5%);
    // }
  }

  .view-content {
    @include flex-grid-row;
    @include flex-align(center, top);
    width: 100%;
    padding-top: rem-calc(25);

    @include breakpoint(large) {
      @include flex-align(justify, stretch);
      padding-top: rem-calc(65);
    }
  }

  .preview-right{
    @include flex-grid-column(11, 0);

    @include breakpoint(medium) {
      @include flex-grid-size(12);
    }

    @include breakpoint(large) {
      @include flex-grid-size(47%);
    }

    .articles-column{
      @include flex-grid-row;
      flex-flow: row wrap;
      align-content: space-between;
      height: 100%;
    }

    .views-row{
      @include hover_animation;
      width: 100%;

      &:first-child{
        margin-bottom: rem-calc(25);

        @include breakpoint(large) {
          margin-bottom: 0;
        }

        &::before{
          display: none;
        }
      }

      & > a{
        display: block;
        overflow: hidden;
        height: 0;
        padding-bottom: 50%;
      }
    }
  }

  .views-row {
    position: relative;

    &.views-row-first {
      @include flex-grid-column(11, 0);
      @include hover_animation;
      margin-bottom: rem-calc(25);

      @include breakpoint(medium) {
        @include flex-grid-size(12);
      }

      @include breakpoint(large) {
        @include flex-grid-size(6);
        margin-bottom: rem-calc(0);
      }

      &::before {
        content: "";
        position: absolute;
        left: rem-calc(-7.5);
        bottom: rem-calc(-7.5);
        width: 50%;
        height: 50%;
        background: url('../img/bg_pattern_home_dandelion_pink.png');

        @include breakpoint(large) {
          left: rem-calc(-30);
          bottom: rem-calc(-30);
          width: rem-calc(237);
          height: rem-calc(237);
        }
      }

      & > a{
        display: block;
        overflow: hidden;
        height: 0;
        padding-bottom: 100%;
      }

      .focus__title {
        bottom: rem-calc(67);
        font-size: rem-calc(45);
        line-height: rem-calc(40);

        @include breakpoint(medium only) {
          font-size: rem-calc(37);
          line-height: rem-calc(30);
        }

        @include breakpoint(large) {
          font-size: rem-calc(62);
          line-height: rem-calc(52);
        }
      }
    }

    &.views-row-last{
      @include breakpoint(large) {
        margin-top: 6%;
      }
    }

    &:not(:first-child) {
      /*max-height: rem-calc(285);*/
    }


  }

  img {
    display: block;
    min-width: 100%;
    width: auto;
    min-height: 100%;
    height: auto;
  }

  &__article {
    background: transparent;
    display: block;
    position: relative;
    //z-index: 2;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60%;
      background-image: linear-gradient(to top, rgba(43,43,44,0.8) 0%, rgba(125,185,232,0) 100%);
    }
  }

  .article__type {
    left: rem-calc(20);

    @include breakpoint(medium) {
      left: rem-calc(40);
    }
  }

  &__title {
    position: absolute;
    z-index: 2;
    bottom: rem-calc(46);
    left: rem-calc(20);
    right: rem-calc(20);
    word-wrap: break-word;
    display: inline-block;
    font-size: rem-calc(24);
    line-height: rem-calc(22);
    color: #ffffff;

    @include breakpoint(medium) {
      left: rem-calc(40);
      right: rem-calc(40);
    }

    @include breakpoint(medium only) {
      font-size: rem-calc(27);
      line-height: rem-calc(24);
    }

    @include breakpoint(large) {
      font-size: rem-calc(38);
      line-height: rem-calc(32);
    }
  }

  &__category {
    position: absolute;
    //z-index: 3;
    bottom: rem-calc(12);
    left: rem-calc(20);

    @include breakpoint(medium) {
    bottom: rem-calc(20);
    left: rem-calc(40);
    }

    a {
      display: inline-block;
      font-family: 'ActaDisplay', Georgia, serif;
      font-weight: 500;
      font-style: italic;
      color: #dbe1e7;
    }
  }

}

.baseline {
  position: relative;
  padding-bottom: rem-calc(25);

  @include breakpoint(medium) {
    padding-bottom: rem-calc(75);
  }

  h1 {
    font-size: rem-calc(29);
    font-weight: 200;
    line-height: rem-calc(29);
    text-align: center;

    @include breakpoint(medium) {
      font-size: rem-calc(36);
      line-height: rem-calc(36);
    }
  }
}

.page-home {
  section[role=main] {
    padding: 0;
  }
}
